import { createBrowserHistory, To } from "history";
import React, { useEffect, useState } from "react";

const history = createBrowserHistory();

let navigationInhibitedMessage: string | null = null;

let locationEffectCallbacks: ((newLocation: To) => void)[] = [];

export function useLocation() {
  const [location, setLocation] = useState(history.location.pathname);
  useEffect(() => {
    return history.listen((newLocation) => {
      setLocation(newLocation.location.pathname);
    });
  }, []);
  return location;
}

export function useQuery() {
  const [query, setQuery] = useState(history.location.search);
  useEffect(() => {
    return history.listen((newLocation) => {
      setQuery(newLocation.location.search);
    });
  }, []);
  return query;
}

export function goToLocation(path: To) {
  if (navigationInhibitedMessage !== null) {
    alert(navigationInhibitedMessage);
    return;
  }
  locationEffectCallbacks.forEach((cb) => cb(path));
  history.push(path);
}

export function goToLocationReplace(path: To) {
  if (navigationInhibitedMessage !== null) {
    alert(navigationInhibitedMessage);
    return;
  }
  locationEffectCallbacks.forEach((cb) => cb(path));
  history.replace(path);
}

export function useInhibitNavigation(message: string) {
  useEffect(() => {
    navigationInhibitedMessage = message;
    return () => {
      navigationInhibitedMessage = null;
    };
  }, [message]);
}

export function useLocationEffect(callback: (newLocation: To) => void) {
  useEffect(() => {
    locationEffectCallbacks.push(callback);
    return () => {
      locationEffectCallbacks = locationEffectCallbacks.filter((cb) => cb !== callback);
    };
  }, [callback]);
}

const secureProtocols = ["https://", "http://", "tel:", "mailto:"];

const linkStyle = {
  color: "inherit",
  textDecoration: "inherit",
  display: "block",
};

export function isGoodURL(url: string): boolean {
  if (url.match(/^\/([^/]|$)/g)) return true;
  return secureProtocols.find((proto) => url.startsWith(proto)) !== undefined;
}

export function Link(props: { path: string; children: React.ReactNode; activeColor?: string }) {
  const location = useLocation();

  if (props.path.match(/^\/([^/]|$)/g)) {
    // Local path, short-circuit routing
    return (
      <a
        href={props.path}
        onClick={(e) => {
          e.preventDefault();
          goToLocation({ pathname: props.path, search: "" });
        }}
        style={{
          ...linkStyle,
          backgroundColor:
            props.activeColor && props.path === location ? props.activeColor : "inherit",
        }}
      >
        {props.children}
      </a>
    );
  }

  const protocol = secureProtocols.find((proto) => props.path.startsWith(proto));
  if (protocol === undefined) {
    // Insecure protocol, don't render link
    console.error(`Insecure protocol for URL '${props.path}', not rendering`);
    return <>{props.children}</>;
  }
  // External safe link
  return (
    <a href={props.path} style={linkStyle}>
      {props.children}
    </a>
  );
}
