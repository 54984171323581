import Avatar from "boring-avatars";
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { idpc } from "../api";
import { Button, Danger } from "../Button";
import { GetSessionsResponse } from "../generated/idp/api/idp";

const Container = styled.div``;

const CurrentUserName = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const CurrentUserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UserImage = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 32px;
`;

export function SelectAccount(props: {
  onLogout: () => void;
  onNewSession: () => void;
}): JSX.Element {
  const [sessions, setSessions] = useState<GetSessionsResponse | null>(null);

  useEffect(() => {
    let ignore = false;
    async function fetchData() {
      try {
        const res = await idpc.GetSessions({});
        if (!ignore) setSessions(res);
      } catch (err) {
        alert(err);
      }
    }
    fetchData();
    return () => {
      ignore = true;
    };
  }, []);
  const currentSession = sessions?.session[sessions.selectedUserSession];
  return (
    <Container>
      <CurrentUserContainer>
        {currentSession?.picture === "" ? (
          <Avatar size={64} name={currentSession?.name} variant="marble" />
        ) : (
          <UserImage src={currentSession?.picture} />
        )}
        <CurrentUserName>{currentSession?.name}</CurrentUserName>
        <Button onClick={props.onLogout} color={Danger}>
          Logout
        </Button>
      </CurrentUserContainer>
      Andere Accounts:
      <ul>
        {sessions?.session
          .filter((_, i) => i !== sessions.selectedUserSession)
          .map((s) => (
            <li>{s.name}</li>
          ))}
        <li>
          <Button onClick={props.onNewSession}>Mit anderem Benutzer anmelden</Button>
        </li>
      </ul>
    </Container>
  );
}
