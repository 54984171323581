import { getSession } from "../api";
import { AuthContinue } from "../generated/idp/api/idp";

export async function authKerberos(cb: (c: AuthContinue) => void, onError?: (err: string) => void) {
  try {
    const session = getSession();
    if (session) {
      const controller = new AbortController();
      // Kill Kerberos request if it hangs as it's most likely prompting for credentials
      // which we don't want.
      setTimeout(() => {
        controller.abort();
      }, 1000);
      const res = await fetch("/api/krb5", {
        headers: new Headers({ session: session }),
        signal: controller.signal,
      });
      if (res.status === 200) {
        const arrayBuf = await res.arrayBuffer();
        const rawMsg = new Uint8Array(arrayBuf);
        const continueData = AuthContinue.decode(rawMsg);
        cb(continueData);
      } else {
        if (onError) onError(await res.text());
      }
    }
  } catch (err) {
    if (onError) onError(err.toString());
    console.error(err);
  }
}
