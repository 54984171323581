import { grpc } from "@improbable-eng/grpc-web";
import { mdiCellphone, mdiDeleteOutline, mdiKey, mdiWindowClose } from "@mdi/js";
import Icon from "@mdi/react";
import { lighten } from "polished";
import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import styled from "styled-components/macro";
import { getErrorDetail, idpc } from "../api";
import {
  AuthContext,
  Configuration,
  TOTPAuthenticator,
  User,
  WebauthnAuthenticator,
} from "../generated/idp/api/idp";
import { AuthPasswordExtension, ChangePassword } from "../login/Password";
import { Spinner } from "../Spinner";
import { Authenticator } from "./Authenticator";
import { ReactComponent as CloudBase } from "./cloud.svg";
import { ReactComponent as MailBase } from "./mail.svg";
import { ReactComponent as MoodleBase } from "./moodle.svg";
import { Nav } from "./Nav";
import { ReactComponent as OfficeBase } from "./office-1.svg";
import { Button } from "../Button";
import { Checkbox } from "../Checkbox";


const FlexRow = styled.div`
  display: flex;
  flex-flow: row;
`;

const Title = styled.h2`
  margin-bottom: 6px;
  margin-top: 25px;
`;

const MoodleIcon = styled(MoodleBase)`
  height: 22px;
  width: 33px;

  @media (max-width: 360px) {
    height: 20px;
  }
`;

const MailIcon = styled(MailBase)`
  height: 21px;
  width: 28px;

  @media (max-width: 360px) {
    height: 19px;
  }
`;

const CloudIcon = styled(CloudBase)`
  height: 22px;
  width: 31px;

  @media (max-width: 360px) {
    height: 20px;
  }
`;

const OfficeIcon = styled(OfficeBase)`
  height: 22px;
  width: 31px;

  @media (max-width: 360px) {
    height: 20px;
  }
`;

export function Account(props: {
  onLogout: () => void;
  authenticate: (a: AuthContext) => void;
}): JSX.Element {
  const [user, setUser] = useState<User | null>(null);
  const [passwordModalOpen, setPasswordModalOpen] = useState<boolean>(false);
  const [configuration, setConfiguration] = useState<Configuration>(Configuration.create({}));
  const authenticate = props.authenticate;

  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      try {
        const res = await idpc.GetUser({});
        if (!ignore) {
          setUser(res.user ?? null);
        }
      } catch (err) {
        if (err.code === grpc.Code.Unauthenticated) {
          authenticate({
            $type: "idp.AuthContext",
            authenticationContextClass: [],
            maxAgeSeconds: 99999999,
          });
        }
        let ia = getErrorDetail(err, AuthContext);
        if (ia !== null) {
          authenticate(ia);
        }
      }
    };
    fetchData();
    return () => {
      ignore = true;
    };
  }, [authenticate]);

  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      try {
        const res = await idpc.GetConfiguration({});
        if (!ignore) {
          setConfiguration(res);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
    return () => {
      ignore = true;
    };
  }, []);

  const onPasswordChange = () => {
    setPasswordModalOpen(true);
  };

  const setEnhancedSecurity = useCallback(async (enabled: boolean) => {
    try {
      console.log(enabled);
      await idpc.SetUserEnhancedSecurity({ enabled });
    } catch (ex) {
      alert("Failed setting enhanced security: " + ex);
    }
  }, []);

  if (user)
    console.log(user.enhancedAuthentication);
  if (user)
    return (
      <Nav profile={user.profile} onLogout={props.onLogout}>
        <FlexRow>
          {/* {user.profile?.picture ? (
            <ProfileIcon src={user.profile.picture}></ProfileIcon>
          ) : (
            <Avatar
              name={user?.profile?.name ?? ""}
              size={32}
              variant="marble"
            />
          )} */}
          <div style={{ alignSelf: "center", marginLeft: 0, fontSize: "1.4rem" }}>
            <div style={{ fontSize: "36px", fontWeight: 600 }}>Hallo,</div>
            {user.profile?.name}
          </div>
        </FlexRow>
        {/* <div>{user.profile?.givenName}</div>
        <div>{user.profile?.familyName}</div>
        <div>{user.profile?.gender}</div>
        <div>{user.profile?.picture}</div>
        <div>{user.profile?.locale}</div>
        <div>{user.profile?.zoneinfo}</div> */}

        <p style={{ marginTop: 40 }}>
          Du hast dich erfolgreich angemeldet. Hier findest du weitere Seiten, an denen du dich
          jetzt anmelden kannst:
        </p>
        <div style={{ marginTop: 20, display: "flex", flexWrap: "wrap" }}>
          <a
            href="https://moodle.bsrueti.ch/moodle/"
            style={{ textDecoration: "none", color: "black" }}
          >
            <div
              style={{
                marginRight: 30,
                marginTop: 10,
                textAlign: "center",
                borderRadius: "10%",
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
                padding: 15,
                minWidth: 80,
                backgroundColor: "white",
              }}
            >
              <MoodleIcon height={22} />
              <div style={{ fontWeight: 600, marginTop: 2, fontSize: "1.1rem" }}>Moodle</div>
            </div>
          </a>
          <a
            href="https://login.microsoftonline.com/?whr=bsrueti.ch&sso_reload=true"
            style={{ textDecoration: "none", color: "black" }}
          >
            <div
              style={{
                marginRight: 30,
                marginTop: 10,
                textAlign: "center",
                borderRadius: "10%",
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
                padding: 15,
                minWidth: 80,
                backgroundColor: "white",
              }}
            >
              <OfficeIcon height={22} />
              <div
                style={{
                  fontWeight: 600,
                  marginTop: 2,
                  fontSize: "1.1rem",
                  color: "black",
                }}
              >
                Office365
              </div>
            </div>
          </a>
          <a href="https://cloud.bsrueti.ch/" style={{ textDecoration: "none", color: "black" }}>
            <div
              style={{
                marginRight: 30,
                marginTop: 10,
                textAlign: "center",
                borderRadius: "10%",
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
                padding: 15,
                minWidth: 80,
                backgroundColor: "white",
              }}
            >
              <CloudIcon height={22} />
              <div style={{ fontWeight: 600, marginTop: 2, fontSize: "1.1rem" }}>Cloud</div>
            </div>
          </a>
          <a href="https://webmail.bsrueti.ch/" style={{ textDecoration: "none", color: "black" }}>
            <div
              style={{
                marginRight: 30,
                marginTop: 10,
                textAlign: "center",
                borderRadius: "10%",
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
                padding: 15,
                minWidth: 80,
                backgroundColor: "white",
              }}
            >
              <MailIcon height={22} />
              <div style={{ fontWeight: 600, marginTop: 2, fontSize: "1.1rem" }}>Webmail</div>
            </div>
          </a>
        </div>
        <hr style={{ marginTop: 20 }}></hr>
        <Title>Passwort</Title>
        Zuletzt geändert: {user.password?.lastChanged?.toLocaleDateString()}<br />
        <Button onClick={() => setPasswordModalOpen(true)}>Passwort ändern</Button>
        <PasswordChangeModal
          open={passwordModalOpen}
          onClose={() => {
            setPasswordModalOpen(false);
          }}
        />
        <Authenticator authenticate={props.authenticate} />
        {configuration.userEnhancedSecurity?.available ? (
          <>
            <Title>Sicherheitsrichtlinen</Title>
            <Checkbox onChange={setEnhancedSecurity} initialValue={user.enhancedAuthentication}>Erweiterte Accountsicherheit aktivieren</Checkbox>
            {configuration.userEnhancedSecurity.description}
          </>
        ) : null}
      </Nav>
    );
  else
    return (
      <Nav>
        <Spinner />
      </Nav>
    );
}

export function WebauthnFactorItem(props: { spec: WebauthnAuthenticator }): JSX.Element {
  const onDeleteCb = useCallback(async () => {
    await idpc.RemoveWebauthn({
      id: props.spec.id,
    });
  }, [props]);
  return <DeviceItem icon={mdiKey} name={props.spec.name} onDelete={onDeleteCb} />;
}

export function TOTPAuthenticatorItem(props: {
  spec: TOTPAuthenticator;
  onClick?: () => void;
}): JSX.Element {
  const onDeleteCb = useCallback(async () => {
    await idpc.RemoveTOTPAuthenticator({
      id: props.spec.id,
    });
  }, [props]);
  return <DeviceItem icon={mdiCellphone} name={props.spec.name} onDelete={onDeleteCb} />;
}

const DeviceContainer = styled.div`
  background: #d6d6d6;
  text-align: left;
  display: flex;
  align-items: center;
  border-radius: 25px;
  padding-left: 20px;
  padding-right: 20px;
  height: 50px;
  margin-bottom: 20px;
`;

const DeleteButton = styled.button`
  border: none;
  cursor: pointer;
  background: none;
`;

const ItemDeleteIcon = styled(Icon)`
  color: #57b846;
  :hover {
    background: ${lighten(0.05, "#d6d6d6")};
  }
`;

const ItemText = styled.div`
  flex-grow: 1;
`;

export function DeviceItem(props: {
  icon: string;
  name: string;
  onDelete: () => void;
}): JSX.Element {
  return (
    <DeviceContainer>
      <Icon path={props.icon} size={1} style={{ marginRight: "10px" }} />
      <ItemText>{props.name}</ItemText>
      <DeleteButton onClick={props.onDelete}>
        <ItemDeleteIcon path={mdiDeleteOutline} size={1} />
      </DeleteButton>
    </DeviceContainer>
  );
}

const customStyles: Modal.Styles = {
  content: {
    fontFamily: "sans-serif",
    maxWidth: 500,
    position: "unset",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

const CloseButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  height: 100%;
  padding-top: 5px;
`;

function PasswordChangeModal(props: { open: boolean; onClose: () => void }) {
  return (
    <Modal isOpen={props.open} onRequestClose={props.onClose} style={customStyles}>
      <ModalTitle>
        <h1>Passwort ändern</h1>
        <CloseButton onClick={props.onClose}>
          <Icon path={mdiWindowClose} size={1} />
        </CloseButton>
      </ModalTitle>

      <ChangePassword
        authRecover={() => {
          props.onClose();
        }}
        reason={AuthPasswordExtension.UserRequested}
      ></ChangePassword>
    </Modal>
  );
}
