import React, { useCallback, useState } from "react";
import { Nav } from "../account/Nav";
import { idpac } from "../api";
import { Button } from "../Button";
import { AuthContext, ServiceAccount } from "../generated/idp/api/idp";
import { Input } from "../Input";

export function ServiceAccounts(props: {
  onLogout: () => void;
  authenticate: (a: AuthContext) => void;
}): JSX.Element {
  const [identity, setIdentity] = useState("");
  const [error, setError] = useState<string | null>(null);
  const issue = useCallback(async () => {
    try {
      setError(null);
      const res = await idpac.CreateServiceAccount({ identity });
      if (res.serviceAccount) {
        const sa = ServiceAccount.encode(res.serviceAccount).finish();
        const blob = new Blob([sa], { type: "application/octet-stream" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElementNS("http://www.w3.org/1999/xhtml", "a") as HTMLLinkElement;
        a.rel = "noopener";
        (a as any).download = `${identity}-sa.pb`;
        setTimeout(() => {
          URL.revokeObjectURL(url);
        }, 4e4);
        a.href = url;
        a.click();
        setIdentity("");
      } else {
        setError("Internal Error: success but no service account returned");
      }
    } catch (err) {
      setError("" + err);
    }
  }, [identity]);
  return (
    <Nav>
      <h1>Service Accounts</h1>
      <h2>Create new service account</h2>
      <p>
        <b>WARNING</b> Service Account files contain long-lived credentials and should be handled
        with utmost care.
      </p>
      <Input name="Identity" value={identity} onChange={setIdentity} onEnter={issue} />
      {error != null ? <p style={{ color: "red" }}>{error}</p> : null}
      <Button onClick={issue}>Create</Button>
    </Nav>
  );
}
