import { grpc } from "@improbable-eng/grpc-web";
import { mdiAccount, mdiAccountLock, mdiAlert, mdiAt, mdiHome } from "@mdi/js";
import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components/macro";
import { getErrorDetail, idpc, oauth2c } from "./api";
import { Brand } from "./Brand";
import { Button, Danger, Primary } from "./Button";
import { AuthContext, ClientProfile, UserProfile } from "./generated/idp/api/idp";
import {
  AuthorizeRequest,
  ClaimRequest,
  PKCEChallengeMethod,
  ResponseType,
} from "./generated/idp/api/oauth2/api";
import { DeviceItem } from "./login/DeviceItem";
import { ErrorLayout } from "./login/ErrorLayout";
import { UserProfilePill } from "./login/UserProfilePill";
import { Spinner } from "./Spinner";
import { Background, Card } from "./UI";

const ConsentContainer = styled.div`
  text-align: left;
  margin: 0 auto;
  width: 400px;
`;

const ConsentButtonLayout = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AccessTokenContainer = styled.div`
  width: 100%;
  background: #ccc;
  overflow-x: scroll;
  padding: 5px;
  white-space: nowrap;
`;

interface AuthorizeState {
  loading: boolean;
  needsConsent: boolean;
  extraScopes: string[];
  accessTokenShow?: string;
  error?: string;
  errorDetails?: string;
  profile?: ClientProfile;
}

enum ResponseMode {
  QUERY,
  FRAGMENT,
  FORM_POST,
}

const requireFragmentOrFormResponseTypes = new Set<ResponseType>([
  ResponseType.RESPONSE_TYPE_TOKEN,
  ResponseType.RESPONSE_TYPE_ID_TOKEN,
]);
const queryDefaultResponseTypes = new Set<ResponseType>([
  ResponseType.RESPONSE_TYPE_NONE,
  ResponseType.RESPONSE_TYPE_CODE,
]);

function respond(params: URLSearchParams, mode: ResponseMode, redirectURL: string) {
  const u = new URL(redirectURL);
  switch (mode) {
    case ResponseMode.QUERY:
      for (let [k, v] of params) {
        u.searchParams.set(k, v);
      }
      window.location.replace(u.toString());
      return;
    case ResponseMode.FRAGMENT:
      u.hash = params.toString();
      window.location.replace(u.toString());
      return;
    case ResponseMode.FORM_POST:
      const form = document.createElement("form");
      form.method = "POST";
      form.action = redirectURL;
      for (let [key, val] of params.keys()) {
        const hiddenField = document.createElement("input");
        hiddenField.type = "hidden";
        hiddenField.name = key;
        hiddenField.value = val;
        form.appendChild(hiddenField);
      }
      // This is safe as it's outside the React root.
      document.body.appendChild(form);
      form.submit();
      return;
  }
}

// hasOwnProperty is a custom type guard
function hasOwnProperty<T extends object>(obj: T, key: PropertyKey): key is keyof T {
  return Object.prototype.hasOwnProperty.call(obj, key);
}

const userinfoKey = "userinfo";
const idTokenKey = "id_token";
const essentialKey = "essential";
const valueKey = "value";
const valuesKey = "values";

function parseClaimsSection(raw: unknown): ClaimRequest[] {
  const claims: ClaimRequest[] = [];
  if (typeof raw === "object" && raw !== null) {
    for (let key in raw) {
      const rawKey: string = key;
      if (hasOwnProperty(raw, key)) {
        const claimRaw: unknown = raw[key];

        let locale = "";
        let name = rawKey;
        const claimNameParts = rawKey.split("#");
        if (claimNameParts.length > 1) {
          locale = claimNameParts[claimNameParts.length - 1];
          name = claimNameParts.slice(0, -1).join("#");
        }

        if (claimRaw === null) {
          claims.push({
            $type: "idp.oauth2.ClaimRequest",
            essential: false,
            name: name,
            locale: locale,
            value: [],
            // Set later
            inIdToken: false,
            inUserinfo: false,
          });
        } else if (typeof claimRaw === "object" && claimRaw !== null) {
          let essential: boolean = false;
          let values: string[] = [];
          if (hasOwnProperty(claimRaw, essentialKey)) {
            const essentialProp = claimRaw[essentialKey];
            if (typeof essentialProp === "boolean") {
              essential = essentialProp;
            } else {
              throw new Error("essential claim request property is not a boolean");
            }
          }
          if (hasOwnProperty(claimRaw, valueKey)) {
            const valueProp = claimRaw[valueKey];
            if (typeof valueProp === "string") {
              values.push(valueProp);
            } else {
              throw new Error("value claim request property is not a string");
            }
          }
          if (hasOwnProperty(claimRaw, valuesKey)) {
            const valuesProp: unknown = claimRaw[valuesKey];
            if (Array.isArray(valuesProp)) {
              if (valuesProp.every((x) => typeof x === "string")) values = valuesProp;
            } else {
              throw new Error("values claim request property is not an array of string");
            }
          }
          claims.push({
            $type: "idp.oauth2.ClaimRequest",
            essential: essential,
            name: name,
            locale: locale,
            value: values,
            // Set later
            inIdToken: false,
            inUserinfo: false,
          });
        }
      }
    }
  } else {
    throw new Error("invalid claims spec: userinfo not object");
  }
  return claims;
}

function parseClaimRequests(raw: string): ClaimRequest[] {
  const tempClaimsMap = new Map<string, ClaimRequest>();
  try {
    const claimsUnknown: unknown = JSON.parse(raw);
    if (typeof claimsUnknown === "object" && claimsUnknown !== null) {
      if (hasOwnProperty(claimsUnknown, userinfoKey)) {
        const userinfoUnknown: unknown = claimsUnknown[userinfoKey];
        const userinfoClaims = parseClaimsSection(userinfoUnknown);
        userinfoClaims.forEach((x) => tempClaimsMap.set(x.name, x));
      }
      if (hasOwnProperty(claimsUnknown, idTokenKey)) {
        const idtTokenUnknown: unknown = claimsUnknown[idTokenKey];
        const idTokenClaims = parseClaimsSection(idtTokenUnknown);
        idTokenClaims.forEach((x) => {
          if (tempClaimsMap.has(x.name)) {
            const claim = tempClaimsMap.get(x.name)!;
            claim.essential = claim.essential || x.essential; // If any spec has essential set, set it
            // TODO(lorenz): Deal with other differences. Spec is ambiguous.
          } else {
            tempClaimsMap.set(x.name, x);
          }
        });
      }
    }
  } catch (err) {
    alert("Bad claims: " + err.toString());
  }
  const res: ClaimRequest[] = [];
  tempClaimsMap.forEach((v) => res.push(v));
  return res;
}

interface ScopeMeta {
  icon: string;
  description: string;
}

const scopeMeta: { [key: string]: ScopeMeta | null } = {
  openid: null,
  profile: {
    icon: mdiAccount,
    description: "Ihr Profil (Name, Profilfoto, Sprache)",
  },
  email: {
    icon: mdiAt,
    description: "Ihre E-Mail-Adresse",
  },
  address: {
    icon: mdiHome,
    description: "Ihre Adresse",
  },
  "bsrueti.ch/employeeId": {
    icon: mdiAccountLock,
    description: "Ihre kantonale ID",
  },
  "idp.internal/admin": {
    icon: mdiAlert,
    description: "IDP-Einstellungen ändern",
  },
};

export function OAuth(props: {
  profile: UserProfile | null;
  authenticate: (a: AuthContext | null, c?: ClientProfile, usernameHint?: string) => void;
}): JSX.Element {
  const theme = useTheme();

  const [authorizeState, setAuthorizeState] = useState<AuthorizeState>({
    loading: true,
    extraScopes: [],
    needsConsent: false,
  });

  const [givenConsent, giveConsent] = useState<string[]>([]);

  const { authenticate } = props;

  const [userProfile, setUserProfile] = useState(props.profile);
  useEffect(() => {
    let ignore = false;
    if (userProfile === null) {
      const fetchData = async () => {
        try {
          const { user } = await idpc.GetUser({});
          if (user && !ignore) {
            setUserProfile(user.profile ?? null);
          }
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();
    }
    return () => {
      ignore = true;
    };
  }, [userProfile]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const responseTypesRaw = params.get("response_type")?.split(" ") ?? [];
    const responseTypes: ResponseType[] = responseTypesRaw.flatMap((x) => {
      switch (x.toLowerCase()) {
        case "none":
          return [ResponseType.RESPONSE_TYPE_NONE];
        case "code":
          return [ResponseType.RESPONSE_TYPE_CODE];
        case "id_token":
          return [ResponseType.RESPONSE_TYPE_ID_TOKEN];
        case "token":
          return [ResponseType.RESPONSE_TYPE_TOKEN];
        default:
          return [];
      }
    });
    const codeChallenge = params.get("code_challenge") ?? "";
    let codeChallengeMethod: PKCEChallengeMethod = PKCEChallengeMethod.PKCE_METHOD_NONE;
    const codeChallengeMethodRaw = params.get("code_challenge_method") ?? "plain";
    switch (codeChallengeMethodRaw) {
      case "S256":
        codeChallengeMethod = PKCEChallengeMethod.PKCE_METHOD_S256;
        break;
      case "plain":
        codeChallengeMethod = PKCEChallengeMethod.PKCE_METHOD_PLAIN;
        break;
      case "":
        // RFC7636 Section 4.3 states that if a code_challenge is sent with no method, the method is "plain".
        codeChallengeMethod =
          codeChallenge === ""
            ? PKCEChallengeMethod.PKCE_METHOD_NONE
            : PKCEChallengeMethod.PKCE_METHOD_S256;
    }

    const promptRaw = params.get("prompt");
    const loginHint = params.get("login_hint");
    const claimsRaw = params.get("claims");
    let claimRequests: ClaimRequest[] = [];
    if (claimsRaw !== null && claimsRaw !== "") {
      claimRequests = parseClaimRequests(claimsRaw);
    }
    const req: AuthorizeRequest = AuthorizeRequest.fromPartial({
      clientId: params.get("client_id") ?? "",
      redirectUri: params.get("redirect_uri") ?? "",
      nonce: params.get("nonce") ?? "",
      maxAge: parseInt(params.get("max_age") ?? "-1", 10) + 1,
      codeChallenge: codeChallenge,
      scope: params.get("scope")?.split(" ") ?? [],
      codeChallengeMethod: codeChallengeMethod,
      claimsLocale: params.get("claims_locales")?.split(" ") ?? [],
      requestedAcr: params.get("acr_values")?.split(" ") ?? [],
      consentScopes: givenConsent,
      responseType: responseTypes,
      claimRequest: claimRequests,
    });
    let responseMode: ResponseMode | null = null;

    const responseModeRaw = params.get("response_mode");
    switch (responseModeRaw) {
      case "query":
        responseMode = ResponseMode.QUERY;
        break;
      case "fragment":
        responseMode = ResponseMode.FRAGMENT;
        break;
      case "form_post":
        responseMode = ResponseMode.FORM_POST;
        break;
      case "":
      case null:
        if (responseTypes.findIndex((t) => requireFragmentOrFormResponseTypes.has(t)) !== -1)
          responseMode = ResponseMode.FRAGMENT;

        if (
          responseMode === null &&
          responseTypes.findIndex((t) => queryDefaultResponseTypes.has(t)) !== -1
        )
          responseMode = ResponseMode.QUERY;

        break;
      default:
        // TODO(lorenz): Report to user
        alert("Garbage response mode");
    }
    if (
      responseMode !== ResponseMode.FRAGMENT &&
      responseMode !== ResponseMode.FORM_POST &&
      responseTypes.findIndex((t) => requireFragmentOrFormResponseTypes.has(t)) !== -1
    )
      alert("Bad request");
    if (responseMode === null) {
      // Last-resort default
      responseMode = ResponseMode.QUERY;
    }

    let ignore = false;
    const fetchData = async () => {
      try {
        const res = await oauth2c.Authorize(req);
        if (!ignore) {
          switch (res.type?.$case) {
            case "data":
              let resData = res.type.data;
              const responseDataOut = new URLSearchParams();
              if (resData.code !== "") responseDataOut.set("code", resData.code);
              if (resData.accessToken !== "") {
                responseDataOut.set("access_token", resData.accessToken);
                responseDataOut.set("token_type", resData.tokenType);
              }
              if (resData.expiresIn !== 0)
                responseDataOut.set("expires_in", resData.expiresIn.toString(10));
              if (resData.idToken !== "") responseDataOut.set("id_token", resData.idToken);
              if (resData.scope.length > 0) responseDataOut.set("scope", resData.scope.join(" "));
              const state = params.get("state");
              if (state != null) responseDataOut.set("state", state);
              if (req.redirectUri === "urn:ietf:wg:oauth:2.0:oob") {
                setAuthorizeState({
                  extraScopes: [],
                  loading: false,
                  needsConsent: false,
                  accessTokenShow: resData.accessToken,
                });
              } else if (req.redirectUri !== "")
                respond(responseDataOut, responseMode!, req.redirectUri);
              break;
            case "error":
              const error = res.type.error;
              const responseErrorOut = new URLSearchParams();
              responseErrorOut.set("error", error.error);
              if (error.errorDescription !== "")
                responseErrorOut.set("error_description", error.errorDescription);
              if (error.errorUri !== "") responseErrorOut.set("error_uri", error.errorUri);
              if (req.redirectUri !== "") respond(responseErrorOut, responseMode!, req.redirectUri);
              break;
            case "requireConsent":
              if (promptRaw === "none") {
                if (promptRaw === "none") {
                  const responseErrorOut = new URLSearchParams();
                  responseErrorOut.set("error", "interaction_required");
                  if (req.redirectUri !== "")
                    respond(responseErrorOut, responseMode!, req.redirectUri);
                  break;
                }
              }
              setAuthorizeState({
                loading: false,
                needsConsent: true,
                extraScopes: res.type.requireConsent.scopes,
                profile: res.clientProfile,
              });
          }
        }
      } catch (err) {
        const profile = getErrorDetail(err, ClientProfile) ?? undefined;
        switch (err.code) {
          case grpc.Code.Unauthenticated:
            if (promptRaw === "none") {
              const responseErrorOut = new URLSearchParams();
              responseErrorOut.set("error", "login_required");
              if (req.redirectUri !== "") respond(responseErrorOut, responseMode!, req.redirectUri);
              break;
            }
            const authCtx = getErrorDetail(err, AuthContext);
            authenticate(authCtx, profile, loginHint ?? undefined);
            break;
          case grpc.Code.InvalidArgument:
            setAuthorizeState({
              loading: false,
              error: "Fehlerhafte Anfrage",
              errorDetails: "Bad OAuth2 configuration: " + err.message,
              extraScopes: [],
              needsConsent: false,
              profile,
            });
            break;
          case grpc.Code.PermissionDenied:
            setAuthorizeState({
              loading: false,
              error: `Ihnen ist die Anmeldung bei ${
                profile?.displayName ?? "dieser Applikation"
              } nicht erlaubt`,
              errorDetails: theme.branding?.accessDeniedText,
              extraScopes: [],
              needsConsent: false,
              profile,
            });
            break;
          case grpc.Code.NotFound:
            setAuthorizeState({
              loading: false,
              error: "Applikation nicht registriert",
              errorDetails: "Der Administrator muss diese Applikation zuerst registrieren",
              extraScopes: [],
              needsConsent: false,
            });
            break;
          case grpc.Code.DeadlineExceeded:
          case grpc.Code.Canceled:
          case grpc.Code.Unavailable:
            if (!ignore) setTimeout(fetchData, 10000);
            break;
        }
      }
    };
    fetchData();
    return () => {
      ignore = true;
    };
  }, [givenConsent, authenticate, theme]);

  return (
    <Background>
      <Card width={500}>
        <Brand />
        <UserProfilePill
          profile={userProfile ?? undefined}
          clientProfile={authorizeState.profile}
        />
        {authorizeState.error !== undefined ? (
          <ErrorLayout
            icon={mdiAlert}
            primaryMessage={authorizeState.error}
            secondaryMessage={authorizeState.errorDetails}
          />
        ) : null}
        {authorizeState.loading ? <Spinner color="black" /> : null}
        {authorizeState.accessTokenShow ? (
          <div>
            Kopiere das folgende Access Token in die Applikation, um den Zugriff zu gewähren:
            <AccessTokenContainer>{authorizeState.accessTokenShow}</AccessTokenContainer>
          </div>
        ) : null}
        {authorizeState.needsConsent ? (
          <ConsentContainer>
            <h3>Zustimmung erforderlich</h3>
            Möchten Sie sich bei {authorizeState.profile?.displayName} anmelden?
            <br />
            {authorizeState.profile?.displayName} erhält dabei Zugriff auf folgende Daten:
            {authorizeState.extraScopes
              .map((x) => scopeMeta[x])
              .filter((x) => x)
              .map((x) => (
                <DeviceItem icon={x!.icon} name={x!.description} />
              ))}
            <ConsentButtonLayout>
              <Button color={Danger}>Nein</Button>
              <Button color={Primary} onClick={() => giveConsent(authorizeState.extraScopes)}>
                Ja
              </Button>
            </ConsentButtonLayout>
          </ConsentContainer>
        ) : null}
      </Card>
    </Background>
  );
}
