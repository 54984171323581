import { grpc } from "@improbable-eng/grpc-web";
import { mdiAlert, mdiKey, mdiPlay } from "@mdi/js";
import React, { useCallback, useEffect, useState } from "react";
import styled, { useTheme } from "styled-components/macro";
import { clearSession, idpc, newSession } from "./api";
import { Brand } from "./Brand";
import { Button, Secondary } from "./Button";
import { Card } from "./Card";
import {
  AuthContext,
  AuthContinue,
  AuthPasswordResponse_Result,
  ClientProfile,
  EmailAddress,
  Mechanism,
  Trust,
  UserProfile,
} from "./generated/idp/api/idp";
import { DeviceItem } from "./login/DeviceItem";
import { ErrorLayout } from "./login/ErrorLayout";
import { authKerberos } from "./login/kerberos";
import { AuthMail, EmailAddressItem, EmailEnroll } from "./login/Mail";
import { OrLine } from "./login/OrLine";
import { ChangePassword, Password } from "./login/Password";
import { SelectAccount } from "./login/SelectAccount";
import {
  AddTOTPAuthenticator,
  TOTP,
  TOTPAuthenticatorEnrollItem,
  TOTPAuthenticatorItem,
} from "./login/TOTP";
import { Username } from "./login/Username";
import { UserProfilePill } from "./login/UserProfilePill";
import {
  AddWebauthn,
  hasWebauthnDiscoverableCreds,
  Webauthn,
  WebauthnDirectButton,
  WebauthnFactorItem,
} from "./login/Webauthn";
import { Spinner } from "./Spinner";
import { useDebug } from "./useDebug";

const MechanismContainer = styled.div`
  margin-top: 20px;
  @media (min-width: 501px) {
    width: 400px;
  }
`;

const Prompt = styled.div`
  color: #505050;
  margin-bottom: 20px;
`;

const SecondaryArea = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
`;

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
`;

const hasWebauthn = window.PublicKeyCredential ? true : false;

let hasFullFIDO2 = false;

const availableAuthenticators = new Set([Mechanism.USERNAME, Mechanism.PASSWORD, Mechanism.TOTP]);
if (hasWebauthn) {
  availableAuthenticators.add(Mechanism.WEBAUTHN_FACTOR);
}

const buttonAuthenticators = new Set([Mechanism.OIDC]);
if (hasWebauthn) {
  if (
    //@ts-ignore
    typeof PublicKeyCredential.isExternalCTAP2SecurityKeySupported === "function"
  ) {
    //@ts-ignore
    PublicKeyCredential.isExternalCTAP2SecurityKeySupported().then((supported: boolean) => {
      if (supported) buttonAuthenticators.add(Mechanism.WEBAUTHN);
      hasFullFIDO2 = supported;
    });
  } else {
    buttonAuthenticators.add(Mechanism.WEBAUTHN);
    hasFullFIDO2 = true;
  }
}

const invisibleMechanisms = new Set([Mechanism.KERBEROS]);

const fakeEmailAddress: EmailAddress = {
  $type: "idp.EmailAddress",
  domain: "example.com",
  id: new Uint8Array([0x00]),
  localPart: "",
  external: false,
  trust: Trust.VERIFIED,
  lastUse: new Date(),
};

interface SelectedMechanism {
  mechanism: Mechanism;
  instance: number;
}

interface MechanismSelector {
  ui: JSX.Element;
  target: SelectedMechanism;
}

function CurrentMechanism(props: {
  canContinue: AuthContinue | null;
  userProfile: UserProfile | null;
  authCtx?: AuthContext;
  authContinue: (c: AuthContinue) => void;
  authRecover: () => void;
}) {
  const [selectedMechanism, selectMechanism] = useState<SelectedMechanism | null>(null);
  const [ignoreMechanisms, setIgnoreMechanisms] = useState<Mechanism[]>([]);
  // One-way state set to true once the initial hint has been applied.
  const [hintSelected, setHintSelected] = useState(false);
  const [lastKerberosError, setLastKerberosError] = useState("No Kerberos authentication error");

  const theme = useTheme();

  const authContinueCb = useCallback(
    (c: AuthContinue) => {
      selectMechanism(null);
      setHintSelected(false);
      props.authContinue(c);
    },
    [props]
  );

  useDebug("whynokrb5".split(""), () => alert(lastKerberosError), [lastKerberosError]);

  useEffect(() => {
    if (props.canContinue !== null) {
      const invisibleMechs = props.canContinue.nextMechanism.filter((m) =>
        invisibleMechanisms.has(m)
      );
      invisibleMechs
        .filter((m) => ignoreMechanisms.indexOf(m) === -1)
        .forEach((m) => {
          switch (m) {
            case Mechanism.KERBEROS:
              authKerberos(authContinueCb, setLastKerberosError);
              setIgnoreMechanisms((m) => [...m, Mechanism.KERBEROS]);
          }
        });
    }
  }, [authContinueCb, ignoreMechanisms, props]);

  if (props.canContinue === null || props.canContinue.done) {
    return <Spinner color="black" />;
  }

  const selectionItems = props.canContinue.nextMechanism.flatMap((m, i): MechanismSelector[] => {
    switch (m) {
      case Mechanism.USERNAME:
        if (props.canContinue?.userId) {
          return []; // Username is pointless if we have identified the user
        }
        return [
          {
            ui: (
              <DeviceItem
                icon={mdiPlay}
                name="E-Mail eingeben"
                onClick={() => selectMechanism({ mechanism: m, instance: 0 })}
              />
            ),
            target: { mechanism: m, instance: 0 },
          },
        ];
      case Mechanism.PASSWORD:
        return [
          {
            ui: (
              <DeviceItem
                icon={mdiKey}
                name="Passwort eingeben"
                onClick={() => selectMechanism({ mechanism: m, instance: 0 })}
              />
            ),
            target: { mechanism: m, instance: 0 },
          },
        ];
      case Mechanism.WEBAUTHN:
        return hasFullFIDO2 &&
          ((props.canContinue?.webauthnAuthenticator.length ?? 0) > 0 ||
            (hasWebauthnDiscoverableCreds() && !props.canContinue?.userId?.length))
          ? [
            {
              target: { mechanism: m, instance: 0 },
              ui: (
                <DeviceItem
                  icon={mdiKey}
                  name="Mit Gerät anmelden"
                  onClick={() => selectMechanism({ mechanism: m, instance: 0 })}
                />
              ),
            },
          ]
          : [];
      case Mechanism.WEBAUTHN_FACTOR: {
        const canEnroll = props.canContinue?.enrollWebauthn ?? false;
        const items: MechanismSelector[] =
          props.canContinue?.webauthnAuthenticator?.map((a, ai) => ({
            ui: (
              <WebauthnFactorItem
                spec={a}
                onClick={() => selectMechanism({ mechanism: m, instance: ai })}
              />
            ),
            target: { mechanism: m, instance: ai },
          })) ?? [];
        if (canEnroll) {
          items.push({
            ui: (
              <DeviceItem
                icon={mdiKey}
                name="Neues Gerät registrieren"
                onClick={() => selectMechanism({ mechanism: m, instance: -1 })}
              />
            ),
            target: { mechanism: m, instance: -1 },
          });
        }
        return items;
      }
      case Mechanism.TOTP: {
        const canEnroll = props.canContinue?.enrollTotp ?? false;
        const items: MechanismSelector[] =
          props.canContinue?.totpAuthenticator?.map((a, ai) => ({
            ui: (
              <TOTPAuthenticatorItem
                spec={a}
                onClick={() => selectMechanism({ mechanism: m, instance: ai })}
              />
            ),
            target: { mechanism: m, instance: ai },
          })) ?? [];
        if (canEnroll) {
          items.push({
            ui: (
              <TOTPAuthenticatorEnrollItem
                onClick={() => selectMechanism({ mechanism: m, instance: -1 })}
              />
            ),
            target: { mechanism: m, instance: -1 },
          });
        }
        return items;
      }
      case Mechanism.EMAIL: {
        return [
          {
            ui: (
              <EmailAddressItem
                spec={fakeEmailAddress}
                onClick={() => selectMechanism({ mechanism: m, instance: 0 })}
              />
            ),
            target: { mechanism: m, instance: 0 },
          },
        ];
      }
      default:
        return [];
    }
  });

  const mustSelectionItems = selectionItems.filter(
    (i) =>
      !buttonAuthenticators.has(i.target.mechanism) && !invisibleMechanisms.has(i.target.mechanism)
  );

  if (mustSelectionItems.length > 1 && selectedMechanism === null) {
    // TODO: Only hint in selection lists, hinting for button authenticators works but is very
    // unintuitive at the moment.
    /* if (
      (props.canContinue?.mechanismHint ?? Mechanism.UNKNOWN) !== Mechanism.UNKNOWN &&
      !hintSelected &&
      props.canContinue.nextMechanism.find((x) => x === props.canContinue?.mechanismHint) !==
        undefined
    ) {
      const hintedSelectionItems = selectionItems.filter(
        (i) => i.target.mechanism === props.canContinue?.mechanismHint
      );
      if (hintedSelectionItems.length === 1) {
        selectMechanism(hintedSelectionItems[0].target);
        setHintSelected(true);
      }
    } */
    return (
      <div>
        <Prompt>
          Die Benutzung dieser Anwendung erfordert, dass Sie sich mit einem der folgenden Dinge
          authentifizieren. Bitte wählen Sie eines aus:
        </Prompt>
        {selectionItems.map((x) => x.ui)}
      </div>
    );
  } else if (selectionItems.length === 0) {
    return (
      <ErrorLayout
        icon={mdiAlert}
        primaryMessage="Sie können sich nicht anmelden, weil Sie die Sicherheitsanforderungen nicht erfüllen können."
        secondaryMessage={theme.branding?.accessDeniedText}
      />
    );
  } else {
    const buttonMechs = props.canContinue.nextMechanism.filter((m) => buttonAuthenticators.has(m));
    let mechanismOnly: JSX.Element | null = null;
    let finalTarget: SelectedMechanism | undefined;
    if (mustSelectionItems.length > 0) {
      const target =
        mustSelectionItems.length > 1 /*|| selectedMechanism !== null*/
          ? selectedMechanism!
          : mustSelectionItems[0].target;
      finalTarget = target;
      switch (target.mechanism) {
        case Mechanism.USERNAME:
          mechanismOnly = (
            <Username key={Mechanism.USERNAME}
              authContinue={authContinueCb}
              authRecover={props.authRecover}
              hasLegacyUsernames={props.canContinue.hasUsernames}
            />
          );
          break;
        case Mechanism.PASSWORD:
          if (props.canContinue.enrollPassword) {
            mechanismOnly = (
              <ChangePassword key={Mechanism.PASSWORD}
                oldPassword=""
                authRecover={props.authRecover}
                reason={AuthPasswordResponse_Result.INVALID}
              />
            );
          } else {
            mechanismOnly = (
              <Password key={Mechanism.PASSWORD}
                authContinue={authContinueCb}
                authCtx={props.authCtx}
                authRecover={props.authRecover}
                emailAddresses={props.canContinue.emailAddress}
              />
            );
          }
          break;
        case Mechanism.TOTP:
          if (target.instance === -1) {
            mechanismOnly = (
              <AddTOTPAuthenticator key={Mechanism.TOTP}
                user={props.userProfile?.name ?? ""}
                authRecoverCb={props.authRecover}
              />
            );
          } else {
            const totpSpec = props.canContinue.totpAuthenticator[target.instance];
            if (totpSpec === undefined) {
              mechanismOnly = (
                <ErrorLayout
                  icon={mdiAlert}
                  primaryMessage="Interner Fehler"
                  secondaryMessage="TOTP mechanism specified, but no valid authenticators"
                />
              );
            } else {
              mechanismOnly = (
                <TOTP key={Mechanism.TOTP}
                  spec={totpSpec}
                  authCtx={props.authCtx}
                  authRecover={props.authRecover}
                  authContinue={authContinueCb}
                />
              );
            }
          }
          break;
        case Mechanism.WEBAUTHN_FACTOR:
          if (target.instance === -1) {
            mechanismOnly = (
              <AddWebauthn key={Mechanism.WEBAUTHN_FACTOR}
                nonce={props.canContinue.nonce}
                authRecover={props.authRecover}
                userProfile={props.userProfile!}
                userId={props.canContinue.userId}
                otherCreds={props.canContinue.webauthnAuthenticator.map((x) => x.id)}
              />
            );
          } else {
            const webauthnSpec = props.canContinue.webauthnAuthenticator[target.instance];
            if (webauthnSpec === undefined) {
              mechanismOnly = (
                <ErrorLayout
                  icon={mdiAlert}
                  primaryMessage="Interner Fehler"
                  secondaryMessage="WebauthN mechanism specified, but no valid authenticators"
                />
              );
            } else {
              mechanismOnly = (
                <Webauthn key={Mechanism.WEBAUTHN_FACTOR}
                  type="factor"
                  authContinue={authContinueCb}
                  spec={webauthnSpec}
                  authCtx={props.authCtx}
                  authRecover={props.authRecover}
                  nonce={props.canContinue.nonce}
                />
              );
            }
          }
          break;
        case Mechanism.WEBAUTHN:
          mechanismOnly = (
            <Webauthn key={Mechanism.WEBAUTHN}
              type="direct"
              authContinue={authContinueCb}
              spec={props.canContinue.webauthnAuthenticator}
              authCtx={props.authCtx}
              authRecover={props.authRecover}
              nonce={props.canContinue.nonce}
            />
          );
          break;
        case Mechanism.EMAIL:
          mechanismOnly = (
            <AuthMail key={Mechanism.EMAIL} authContinue={authContinueCb} spec={props.canContinue.emailAddress[0]} />
          );
          break;
        case null:
          mechanismOnly = <Spinner key="wait" color="black" />;
          break;
        default:
          mechanismOnly = (
            <ErrorLayout key="error"
              icon={mdiAlert}
              primaryMessage="Interner Fehler"
              secondaryMessage="Invalid mechanism"
            />
          );
      }
    }
    const buttonMechsUI: JSX.Element[] = buttonMechs.flatMap((m) => {
      switch (m) {
        case Mechanism.WEBAUTHN:
          if (props.canContinue && finalTarget?.mechanism !== m)
            return [
              <WebauthnDirectButton key={Mechanism.WEBAUTHN}
                nonce={props.canContinue.nonce}
                authCtx={props.authCtx}
                spec={props.canContinue.webauthnAuthenticator}
                authRecover={props.authRecover}
                authContinue={authContinueCb}
              />,
            ];
          else return [];
        default:
          return [];
      }
    });
    return (
      <>
        {mechanismOnly}
        {buttonMechsUI.length > 0 && selectedMechanism === null ? (
          <>
            {mechanismOnly !== null ? <OrLine text="oder" /> : null}
            <ButtonArea>{buttonMechsUI}</ButtonArea>
          </>
        ) : null}
        {selectedMechanism !== null ? (
          <SecondaryArea>
            <Button color={Secondary} onClick={() => selectMechanism(null)}>
              Anderen Mechanismus auswählen
            </Button>
          </SecondaryArea>
        ) : null}
      </>
    );
  }
}

export function LoginFlow(props: {
  onDone?: (p: UserProfile | null) => void;
  authCtx?: AuthContext;
  usernameHint?: string;
  clientProfile?: ClientProfile;
}): JSX.Element {
  const { onDone, authCtx } = props;
  const [authContinue, setAuthContinue] = useState<AuthContinue | null>(null);
  const [error, setError] = useState<grpc.Code | null>(null);
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [sessionLogicalClock, setSessionLogicalClock] = useState(0);
  const [isSelectingAccount, setSelectingAccount] = useState(false);
  const [usernameHint, setUsernameHint] = useState(props.usernameHint);

  const authContinueCb = useCallback((c: AuthContinue) => {
    console.log(c);
    if (c.newSessionToken) {
      newSession(c.newSessionToken);
    }
    if (c.userProfile !== undefined) {
      setProfile(c.userProfile);
    }
    setAuthContinue(c);
  }, []);

  useEffect(() => {
    if (authContinue?.done && onDone && !authContinue.enrollEmail) {
      onDone(profile);
    }
  }, [profile, authContinue, onDone]);

  const onSwitch = useCallback(async () => {
    setSelectingAccount(true);
    setProfile(null);
    setSessionLogicalClock((old) => old + 1);
  }, []);

  const authRecoverCb = useCallback(async () => {
    setProfile(null);
    setSessionLogicalClock((old) => old + 1);
  }, []);

  const logoutCb = useCallback(async () => {
    try {
      await idpc.Logout({});
    } catch (err) {
      console.log(err);
    }
    setProfile(null);
    setSessionLogicalClock((old) => old + 1);
    setSelectingAccount(false);
  }, []);

  const startNewSessionCb = useCallback(() => {
    setProfile(null);
    setSelectingAccount(false);
    setSessionLogicalClock((old) => old + 1);
  }, []);

  useEffect(() => {
    let ignore = false;
    let timeout: ReturnType<typeof setTimeout> | null = null;
    async function fetchData() {
      try {
        const res = await idpc.AuthStart({
          authCtx: authCtx,
          usernameHint: usernameHint,
        });
        setError(null);
        setUsernameHint("");
        if (!ignore && res.authContinue) authContinueCb(res.authContinue);
      } catch (err) {
        switch (err?.code) {
          case grpc.Code.Unauthenticated:
            clearSession();
            setProfile(null);
            setTimeout(fetchData, 0);
            break;
          case (grpc.Code.DeadlineExceeded, grpc.Code.Unavailable):
            setError(err.code);
            timeout = setTimeout(fetchData, 10000);
            break;
          case grpc.Code.Aborted:
            setTimeout(fetchData, 100);
            break;
        }
      }
    }
    fetchData();

    return () => {
      ignore = true;
      if (timeout !== null) {
        clearTimeout(timeout);
      }
    };
  }, [authContinueCb, sessionLogicalClock, authCtx]);

  if (isSelectingAccount) {
    return (
      <Card>
        <Brand />
        <SelectAccount onLogout={logoutCb} onNewSession={startNewSessionCb} />
      </Card>
    );
  }

  switch (error) {
    case grpc.Code.DeadlineExceeded:
      return (
        <Card>
          <Brand />
          <MechanismContainer>
            <ErrorLayout
              icon={mdiAlert}
              primaryMessage="Kommunikation mit Server fehlgeschlagen"
              secondaryMessage="Bitte prüfen Sie Ihre Internetverbindung. Wenn das Problem weiterhin besteht, kontaktieren Sie bitte den Technischen Dienst unter technik@bsrueti.ch"
            />
          </MechanismContainer>
        </Card>
      );
    case grpc.Code.Unavailable:
      return (
        <Card>
          <Brand />
          <MechanismContainer>
            <ErrorLayout
              icon={mdiAlert}
              primaryMessage="System nicht verfügbar"
              secondaryMessage="Das System ist im Moment nicht verfügbar. Bitte warten Sie ein paar Minuten. Wenn das Problem weiterhin besteht, kontaktieren Sie bitte den Technischen Dienst unter technik@bsrueti.ch"
            />
          </MechanismContainer>
        </Card>
      );
    default:
      return (
        <Card>
          <Brand />
          {profile || props.clientProfile ? (
            <UserProfilePill
              profile={profile ?? undefined}
              clientProfile={props.clientProfile}
              onSwitch={onSwitch}
            />
          ) : null}
          {authContinue?.enrollEmail ? (
            <EmailEnroll onDone={() => onDone?.(profile)} policy={authContinue.emailEnrollment} />
          ) : (
            <MechanismContainer>
              <CurrentMechanism
                canContinue={authContinue}
                userProfile={profile}
                authCtx={props.authCtx}
                authContinue={authContinueCb}
                authRecover={authRecoverCb}
              />
            </MechanismContainer>
          )}
        </Card>
      );
  }
}
