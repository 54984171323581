import styled from "styled-components/macro";

const Container = styled.div`
  color: red;
  font-weight: 600;
  font-size: 1.1rem;
  margin-top: 10px;
`;

export function ErrorMessage(props: { error: string | null }) {
  if (props.error === null) {
    return null;
  } else {
    return <Container>{props.error}</Container>;
  }
}
