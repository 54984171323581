import { grpc } from "@improbable-eng/grpc-web";
import { mdiClose, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Nav } from "../account/Nav";
import { getErrorDetail, oauth2a } from "../api";
import { Button } from "../Button";
import { AuthContext, ClientProfile, UserProfile } from "../generated/idp/api/idp";
import { Client } from "../generated/idp/api/oauth2/api";
import { FakeUserImage } from "../login/FakeUserImage";
import { nanoid } from "../nanoid";
import { goToLocationReplace, useLocation, useQuery } from "../router";

const HDInput = styled.input`
  background-color: #e6e6e6;
  border: none;
  border-radius: 4px;
  padding: 2px;
  margin: 2px;
  :hover {
    background-color: #c5c5c5;
  }
`;

const HDButton = styled.button`
  background-color: #e6e6e6;
  border: none;
  border-radius: 4px;
  padding: 2px 5px;
  margin: 2px;
  :hover {
    background-color: #c5c5c5;
  }
`;

const HDLabel = styled.label`
  font-weight: bold;
  margin-top: 10px;
`;

function EditableList(props: {
  values: string[];
  inputWidth?: number | string;
  emptyElement?: JSX.Element;
  onChange?: (values: string[]) => void;
}): JSX.Element {
  return (
    <ul style={{ listStyle: "none", paddingLeft: 0, marginTop: 0 }}>
      {props.values.map((x, i) => (
        <li>
          <HDInput
            type="text"
            value={x}
            style={{ width: props.inputWidth ?? 180 }}
            onChange={(e) =>
              props.onChange?.([
                ...props.values.slice(0, i),
                e.currentTarget.value,
                ...props.values.slice(i + 1),
              ])
            }
          />
          <HDButton
            style={{ height: 22, width: 22 }}
            onClick={(e) =>
              props.onChange?.([...props.values.slice(0, i), ...props.values.slice(i + 1)])
            }
          >
            <Icon size={0.5} path={mdiClose} />
          </HDButton>
        </li>
      ))}
      <li>
        {props.values.length === 0 && props.emptyElement !== undefined ? props.emptyElement : null}
      </li>
      <li>
        <HDButton onClick={(e) => props.onChange?.([...props.values, ""])}>
          <Icon size={0.5} path={mdiPlus} />
          Add
        </HDButton>
      </li>
    </ul>
  );
}

function EditableText(props: { value: string; onChange: (n: string) => void }): JSX.Element {
  const [isEditing, setEditing] = useState(false);
  if (!isEditing) {
    return <span onClick={() => setEditing(true)}>{props.value}</span>;
  }
  return (
    <input
      type="text"
      value={props.value}
      onBlur={() => setEditing(false)}
      onChange={(e) => props.onChange(e.currentTarget.value)}
    />
  );
}

export function AppOIDC(props: {
  profile: UserProfile | null;
  onLogout: () => void;
  authenticate: (a: AuthContext) => void;
}): JSX.Element {
  const query = useQuery();
  const isNew = query === "?new";
  const clientID = useLocation().slice(17);
  const [client, setClient] = useState<Client | null>(
    isNew
      ? Client.fromPartial({
          id: clientID,
          // Bug in fromPartial
          secretHash: new Uint8Array(),
          secret: nanoid(32),
          profile: ClientProfile.fromPartial({
            displayName: "Unnamed App",
          }),
        })
      : null
  );
  const authenticate = props.authenticate;

  useEffect(() => {
    if (isNew) return;
    let ignore = false;
    const fetchData = async () => {
      try {
        const res = await oauth2a.ListClients({});
        if (!ignore) {
          const c = res.client.find((x) => x.id === clientID) ?? null;
          setClient(c);
          const secretLen = c?.secretHash?.length ?? 0;
          setIsConfidential(secretLen > 0);
        }
      } catch (err) {
        if (err.code === grpc.Code.Unauthenticated) {
          let ia = getErrorDetail(err, AuthContext);
          if (ia !== null) {
            authenticate(ia);
          }
        }
      }
    };
    fetchData();
    return () => {
      ignore = true;
    };
  }, [authenticate, clientID, isNew]);
  const [isConfidential, setIsConfidential] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const applyCb = useCallback(async () => {
    try {
      setError(null);
      if (client === null) {
        return;
      }
      let finalClient = client;
      if (!isConfidential) {
        finalClient.secret = "";
        finalClient.secretHash = new Uint8Array();
      }
      await oauth2a.ReplaceClient({
        newClient: finalClient,
        oldClientId: isNew ? undefined : client.id,
      });
      if (isNew) {
        // Now no longer new
        goToLocationReplace({ pathname: "/admin/apps/oidc/" + client.id, search: "" });
      }
    } catch (err) {
      setError("" + err);
    }
  }, [client, isNew, isConfidential]);
  if (client === null) {
    return (
      <Nav>
        <h2>Unknown App</h2>
      </Nav>
    );
  }

  return (
    <Nav profile={props.profile ?? undefined} onLogout={props.onLogout}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: 64, height: 64, marginRight: 10 }}>
          {client.profile?.logoUrl !== "" ? (
            <img
              alt={client.profile?.displayName}
              src={client.profile?.logoUrl}
              height="64"
              style={{ maxWidth: 64 }}
            />
          ) : (
            <FakeUserImage name={client.profile.displayName} size={64} />
          )}
        </div>
        <div>
          <div>ID: {client.id}</div>
          <h1 style={{ margin: 0 }}>
            <EditableText
              value={client.profile?.displayName ?? ""}
              onChange={(x) =>
                setClient({
                  ...client,
                  profile: ClientProfile.fromPartial({ ...client.profile, displayName: x }),
                })
              }
            />
          </h1>
          <b>OAuth 2.0 / OIDC</b>
        </div>
      </div>
      <h2>Authentication</h2>
      <HDLabel>Client Type</HDLabel>
      <br />
      <input
        type="radio"
        id="opt-confidential"
        name="auth-type"
        value="confidential"
        checked={isConfidential}
        onChange={(e) => setIsConfidential(e.currentTarget.value === "confidential")}
      />
      <label htmlFor="opt-confidential"> Confidential </label>
      <input
        type="radio"
        id="opt-public"
        name="auth-type"
        value="public"
        checked={!isConfidential}
        onChange={(e) => setIsConfidential(e.currentTarget.value === "confidential")}
      />
      <label htmlFor="opt-public"> Public</label>
      {isConfidential ? (
        <div style={{ marginBottom: 10 }}>
          {client.secret.length > 0 ? (
            <div>
              Client Secret: <span style={{ fontFamily: "monospace" }}>{client.secret}</span> <br />
              Please store the secret now, you won't be able to view it again. The secret is not
              active until it has been stored by clicking "Apply".
            </div>
          ) : null}
          <HDButton onClick={() => setClient(() => ({ ...client, secret: nanoid(32) }))}>
            Regenerate Client Secret
          </HDButton>
        </div>
      ) : (
        <div style={{ marginBottom: 10 }}>
          <input
            type="checkbox"
            id="implicit-flow"
            checked={client.allowImplicit}
            onChange={(e) => setClient({ ...client, allowImplicit: e.currentTarget.checked })}
          />{" "}
          <label htmlFor="implicit-flow">Allow discouraged Implicit Flow</label>
        </div>
      )}
      {/* Tabbed: Public client (allow_implicit) / Confidential client (secret regen/hash) */}
      <HDLabel>Redirect URLs</HDLabel>
      <EditableList
        inputWidth={300}
        values={client.redirectUri}
        onChange={(x) => setClient({ ...client, redirectUri: x })}
      ></EditableList>
      <h2>Scopes</h2>
      <HDLabel>Do not request user consent for these scopes</HDLabel>
      <EditableList
        values={client.autoConsentScope}
        onChange={(x) => setClient({ ...client, autoConsentScope: x })}
      ></EditableList>
      <HDLabel>Grant application the following roles (service account only)</HDLabel>
      <EditableList
        values={client.selfScopes}
        onChange={(x) => setClient({ ...client, selfScopes: x })}
      ></EditableList>
      <h2>Authorization Access Control</h2>
      <HDLabel>Only allow members of the following roles to authorize</HDLabel>
      <EditableList
        values={client.restrictToGroup}
        onChange={(x) => setClient({ ...client, restrictToGroup: x })}
        emptyElement={<i>Empty list, everyone permitted</i>}
      />
      <HDLabel>Only allow authorization with one of the specificed authentication contexts</HDLabel>
      <EditableList
        values={client.restrictToAcr}
        onChange={(x) => setClient({ ...client, restrictToAcr: x })}
        emptyElement={<i>Empty list, all contexts permitted permitted</i>}
      />
      <h2>Compatibility</h2>
      <HDLabel>
        Always include the following claims in the ID token (still subject to OAuth2 scopes)
      </HDLabel>
      <EditableList
        values={client.extraIdTokenClaims}
        onChange={(x) => setClient({ ...client, extraIdTokenClaims: x })}
      />
      <Button onClick={applyCb}>Apply</Button>
      <div style={{ fontWeight: 600, color: "red" }}>{error}</div>
    </Nav>
  );
}
