/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { messageTypeRegistry } from "../../../typeRegistry";

export const protobufPackage = "cryptopb";

export enum SignatureAlgorithm {
  UNKNOWN_SA = 0,
  /** RS1 - RSA PKCS v1.5 over SHA1 (unsafe) RSA_PKCS1V15_SHA1 */
  RS1 = 1,
  /** RS256 - RSA PKCS v1.5 over SHA-256 RSA_PKCS1V15_SHA256 */
  RS256 = 2,
  /** RS384 - RSA PKCS v1.5 over SHA-384 RSA_PKCS1V15_SHA384 */
  RS384 = 3,
  /** RS512 - RSA PKCS v1.5 over SHA-512 RSA_PKCS1V15_SHA512 */
  RS512 = 4,
  /** ES256 - ECDSA P-256 over SHA-256 ECDSA_P256_SHA256 */
  ES256 = 5,
  /** ES384 - ECDSA P-384 over SHA-384 ECDSA_P384_SHA384 */
  ES384 = 6,
  /** ES512 - ECDSA P-521 over SHA-512 ECDSA_P512_SHA512 */
  ES512 = 7,
  /** ED25519 - EdDSA Curve25519 over SHA-512 */
  ED25519 = 8,
  /** ED448 - EdDSA Curve448 over SHAKE256 */
  ED448 = 9,
  UNRECOGNIZED = -1,
}

export enum Curve {
  UNKNOWN_CURVE = 0,
  /** P224 - NIST P-224 as defined in FIPS 186-3 section D.2, aka secp224r1 */
  P224 = 1,
  /** P256 - NIST P-256 as defined in FIPS 186-3 section D.2, aka secp256r1 */
  P256 = 2,
  /** P384 - NIST P-384 as defined in FIPS 186-3 section D.2, aka secp384r1 */
  P384 = 3,
  /** P521 - NIST P-521 as defined in FIPS 186-3 section D.2, aka secp521r1 */
  P521 = 4,
  UNRECOGNIZED = -1,
}

export interface ECDSAPublicKey {
  $type: "cryptopb.ECDSAPublicKey";
  curve: Curve;
  x: Uint8Array;
  yOdd: boolean;
}

export interface ECDSAPrivateKey {
  $type: "cryptopb.ECDSAPrivateKey";
  publicKey: ECDSAPublicKey | undefined;
  d: Uint8Array;
}

export interface RSAPublicKey {
  $type: "cryptopb.RSAPublicKey";
  /** Modulus */
  n: Uint8Array;
  e: number;
}

export interface RSAPrivateKey {
  $type: "cryptopb.RSAPrivateKey";
  publicKey:
    | RSAPublicKey
    | undefined;
  /** Private exponent */
  d: Uint8Array;
  /** Prime factors of N */
  primes: Uint8Array[];
}

export interface EdDSAPublicKey {
  $type: "cryptopb.EdDSAPublicKey";
  /**
   * Y coordinate with its most-significant bit set to the least-significant bit
   * of the X coordinate. Represented as a little-endian unsigned integer.
   */
  compressedPoint: Uint8Array;
}

export interface EdDSAPrivateKey {
  $type: "cryptopb.EdDSAPrivateKey";
  publicKey: EdDSAPublicKey | undefined;
  seed: Uint8Array;
}

export interface PublicKey {
  $type: "cryptopb.PublicKey";
  type?: { $case: "rsa"; rsa: RSAPublicKey } | { $case: "ecdsa"; ecdsa: ECDSAPublicKey } | {
    $case: "eddsa";
    eddsa: EdDSAPublicKey;
  } | undefined;
}

export interface PrivateKey {
  $type: "cryptopb.PrivateKey";
  type?: { $case: "rsa"; rsa: RSAPrivateKey } | { $case: "ecdsa"; ecdsa: ECDSAPrivateKey } | {
    $case: "eddsa";
    eddsa: EdDSAPrivateKey;
  } | undefined;
}

function createBaseECDSAPublicKey(): ECDSAPublicKey {
  return { $type: "cryptopb.ECDSAPublicKey", curve: 0, x: new Uint8Array(0), yOdd: false };
}

export const ECDSAPublicKey = {
  $type: "cryptopb.ECDSAPublicKey" as const,

  encode(message: ECDSAPublicKey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.curve !== 0) {
      writer.uint32(8).int32(message.curve);
    }
    if (message.x.length !== 0) {
      writer.uint32(18).bytes(message.x);
    }
    if (message.yOdd === true) {
      writer.uint32(24).bool(message.yOdd);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ECDSAPublicKey {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseECDSAPublicKey();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.curve = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.x = reader.bytes();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.yOdd = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ECDSAPublicKey>, I>>(base?: I): ECDSAPublicKey {
    return ECDSAPublicKey.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ECDSAPublicKey>, I>>(object: I): ECDSAPublicKey {
    const message = createBaseECDSAPublicKey();
    message.curve = object.curve ?? 0;
    message.x = object.x ?? new Uint8Array(0);
    message.yOdd = object.yOdd ?? false;
    return message;
  },
};

messageTypeRegistry.set(ECDSAPublicKey.$type, ECDSAPublicKey);

function createBaseECDSAPrivateKey(): ECDSAPrivateKey {
  return { $type: "cryptopb.ECDSAPrivateKey", publicKey: undefined, d: new Uint8Array(0) };
}

export const ECDSAPrivateKey = {
  $type: "cryptopb.ECDSAPrivateKey" as const,

  encode(message: ECDSAPrivateKey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.publicKey !== undefined) {
      ECDSAPublicKey.encode(message.publicKey, writer.uint32(10).fork()).ldelim();
    }
    if (message.d.length !== 0) {
      writer.uint32(18).bytes(message.d);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ECDSAPrivateKey {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseECDSAPrivateKey();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.publicKey = ECDSAPublicKey.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.d = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ECDSAPrivateKey>, I>>(base?: I): ECDSAPrivateKey {
    return ECDSAPrivateKey.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ECDSAPrivateKey>, I>>(object: I): ECDSAPrivateKey {
    const message = createBaseECDSAPrivateKey();
    message.publicKey = (object.publicKey !== undefined && object.publicKey !== null)
      ? ECDSAPublicKey.fromPartial(object.publicKey)
      : undefined;
    message.d = object.d ?? new Uint8Array(0);
    return message;
  },
};

messageTypeRegistry.set(ECDSAPrivateKey.$type, ECDSAPrivateKey);

function createBaseRSAPublicKey(): RSAPublicKey {
  return { $type: "cryptopb.RSAPublicKey", n: new Uint8Array(0), e: 0 };
}

export const RSAPublicKey = {
  $type: "cryptopb.RSAPublicKey" as const,

  encode(message: RSAPublicKey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.n.length !== 0) {
      writer.uint32(10).bytes(message.n);
    }
    if (message.e !== 0) {
      writer.uint32(16).int32(message.e);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RSAPublicKey {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRSAPublicKey();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.n = reader.bytes();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.e = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RSAPublicKey>, I>>(base?: I): RSAPublicKey {
    return RSAPublicKey.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RSAPublicKey>, I>>(object: I): RSAPublicKey {
    const message = createBaseRSAPublicKey();
    message.n = object.n ?? new Uint8Array(0);
    message.e = object.e ?? 0;
    return message;
  },
};

messageTypeRegistry.set(RSAPublicKey.$type, RSAPublicKey);

function createBaseRSAPrivateKey(): RSAPrivateKey {
  return { $type: "cryptopb.RSAPrivateKey", publicKey: undefined, d: new Uint8Array(0), primes: [] };
}

export const RSAPrivateKey = {
  $type: "cryptopb.RSAPrivateKey" as const,

  encode(message: RSAPrivateKey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.publicKey !== undefined) {
      RSAPublicKey.encode(message.publicKey, writer.uint32(10).fork()).ldelim();
    }
    if (message.d.length !== 0) {
      writer.uint32(18).bytes(message.d);
    }
    for (const v of message.primes) {
      writer.uint32(26).bytes(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RSAPrivateKey {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRSAPrivateKey();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.publicKey = RSAPublicKey.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.d = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.primes.push(reader.bytes());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RSAPrivateKey>, I>>(base?: I): RSAPrivateKey {
    return RSAPrivateKey.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RSAPrivateKey>, I>>(object: I): RSAPrivateKey {
    const message = createBaseRSAPrivateKey();
    message.publicKey = (object.publicKey !== undefined && object.publicKey !== null)
      ? RSAPublicKey.fromPartial(object.publicKey)
      : undefined;
    message.d = object.d ?? new Uint8Array(0);
    message.primes = object.primes?.map((e) => e) || [];
    return message;
  },
};

messageTypeRegistry.set(RSAPrivateKey.$type, RSAPrivateKey);

function createBaseEdDSAPublicKey(): EdDSAPublicKey {
  return { $type: "cryptopb.EdDSAPublicKey", compressedPoint: new Uint8Array(0) };
}

export const EdDSAPublicKey = {
  $type: "cryptopb.EdDSAPublicKey" as const,

  encode(message: EdDSAPublicKey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.compressedPoint.length !== 0) {
      writer.uint32(10).bytes(message.compressedPoint);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EdDSAPublicKey {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEdDSAPublicKey();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.compressedPoint = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<EdDSAPublicKey>, I>>(base?: I): EdDSAPublicKey {
    return EdDSAPublicKey.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EdDSAPublicKey>, I>>(object: I): EdDSAPublicKey {
    const message = createBaseEdDSAPublicKey();
    message.compressedPoint = object.compressedPoint ?? new Uint8Array(0);
    return message;
  },
};

messageTypeRegistry.set(EdDSAPublicKey.$type, EdDSAPublicKey);

function createBaseEdDSAPrivateKey(): EdDSAPrivateKey {
  return { $type: "cryptopb.EdDSAPrivateKey", publicKey: undefined, seed: new Uint8Array(0) };
}

export const EdDSAPrivateKey = {
  $type: "cryptopb.EdDSAPrivateKey" as const,

  encode(message: EdDSAPrivateKey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.publicKey !== undefined) {
      EdDSAPublicKey.encode(message.publicKey, writer.uint32(10).fork()).ldelim();
    }
    if (message.seed.length !== 0) {
      writer.uint32(18).bytes(message.seed);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EdDSAPrivateKey {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEdDSAPrivateKey();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.publicKey = EdDSAPublicKey.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.seed = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<EdDSAPrivateKey>, I>>(base?: I): EdDSAPrivateKey {
    return EdDSAPrivateKey.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EdDSAPrivateKey>, I>>(object: I): EdDSAPrivateKey {
    const message = createBaseEdDSAPrivateKey();
    message.publicKey = (object.publicKey !== undefined && object.publicKey !== null)
      ? EdDSAPublicKey.fromPartial(object.publicKey)
      : undefined;
    message.seed = object.seed ?? new Uint8Array(0);
    return message;
  },
};

messageTypeRegistry.set(EdDSAPrivateKey.$type, EdDSAPrivateKey);

function createBasePublicKey(): PublicKey {
  return { $type: "cryptopb.PublicKey", type: undefined };
}

export const PublicKey = {
  $type: "cryptopb.PublicKey" as const,

  encode(message: PublicKey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.type?.$case) {
      case "rsa":
        RSAPublicKey.encode(message.type.rsa, writer.uint32(10).fork()).ldelim();
        break;
      case "ecdsa":
        ECDSAPublicKey.encode(message.type.ecdsa, writer.uint32(18).fork()).ldelim();
        break;
      case "eddsa":
        EdDSAPublicKey.encode(message.type.eddsa, writer.uint32(26).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PublicKey {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePublicKey();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = { $case: "rsa", rsa: RSAPublicKey.decode(reader, reader.uint32()) };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.type = { $case: "ecdsa", ecdsa: ECDSAPublicKey.decode(reader, reader.uint32()) };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.type = { $case: "eddsa", eddsa: EdDSAPublicKey.decode(reader, reader.uint32()) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<PublicKey>, I>>(base?: I): PublicKey {
    return PublicKey.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PublicKey>, I>>(object: I): PublicKey {
    const message = createBasePublicKey();
    if (object.type?.$case === "rsa" && object.type?.rsa !== undefined && object.type?.rsa !== null) {
      message.type = { $case: "rsa", rsa: RSAPublicKey.fromPartial(object.type.rsa) };
    }
    if (object.type?.$case === "ecdsa" && object.type?.ecdsa !== undefined && object.type?.ecdsa !== null) {
      message.type = { $case: "ecdsa", ecdsa: ECDSAPublicKey.fromPartial(object.type.ecdsa) };
    }
    if (object.type?.$case === "eddsa" && object.type?.eddsa !== undefined && object.type?.eddsa !== null) {
      message.type = { $case: "eddsa", eddsa: EdDSAPublicKey.fromPartial(object.type.eddsa) };
    }
    return message;
  },
};

messageTypeRegistry.set(PublicKey.$type, PublicKey);

function createBasePrivateKey(): PrivateKey {
  return { $type: "cryptopb.PrivateKey", type: undefined };
}

export const PrivateKey = {
  $type: "cryptopb.PrivateKey" as const,

  encode(message: PrivateKey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.type?.$case) {
      case "rsa":
        RSAPrivateKey.encode(message.type.rsa, writer.uint32(10).fork()).ldelim();
        break;
      case "ecdsa":
        ECDSAPrivateKey.encode(message.type.ecdsa, writer.uint32(18).fork()).ldelim();
        break;
      case "eddsa":
        EdDSAPrivateKey.encode(message.type.eddsa, writer.uint32(26).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PrivateKey {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrivateKey();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = { $case: "rsa", rsa: RSAPrivateKey.decode(reader, reader.uint32()) };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.type = { $case: "ecdsa", ecdsa: ECDSAPrivateKey.decode(reader, reader.uint32()) };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.type = { $case: "eddsa", eddsa: EdDSAPrivateKey.decode(reader, reader.uint32()) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<PrivateKey>, I>>(base?: I): PrivateKey {
    return PrivateKey.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PrivateKey>, I>>(object: I): PrivateKey {
    const message = createBasePrivateKey();
    if (object.type?.$case === "rsa" && object.type?.rsa !== undefined && object.type?.rsa !== null) {
      message.type = { $case: "rsa", rsa: RSAPrivateKey.fromPartial(object.type.rsa) };
    }
    if (object.type?.$case === "ecdsa" && object.type?.ecdsa !== undefined && object.type?.ecdsa !== null) {
      message.type = { $case: "ecdsa", ecdsa: ECDSAPrivateKey.fromPartial(object.type.ecdsa) };
    }
    if (object.type?.$case === "eddsa" && object.type?.eddsa !== undefined && object.type?.eddsa !== null) {
      message.type = { $case: "eddsa", eddsa: EdDSAPrivateKey.fromPartial(object.type.eddsa) };
    }
    return message;
  },
};

messageTypeRegistry.set(PrivateKey.$type, PrivateKey);

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P> | "$type">]: never };
