import { grpc } from "@improbable-eng/grpc-web";
import { mdiCellphone, mdiKeyRemove, mdiTimerSand } from "@mdi/js";
import QRCode from "qrcode.react";
import React, { useCallback, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components/macro";
import { idpc } from "../api";
import { Button } from "../Button";
import { ErrorMessage } from "../ErrorMessage";
import {
  AuthContext,
  AuthContinue,
  AuthTOTPResponse_Result,
  TOTPAuthenticator,
} from "../generated/idp/api/idp";
import { Input } from "../Input";
import { DeviceItem } from "./DeviceItem";
import { ErrorLayout } from "./ErrorLayout";

const Container = styled.div``;

const Prompt = styled.div`
  color: #505050;
`;

const QRContainer = styled.div`
  text-align: center;
  margin: 10px;
`;

// RFC3548 Table 3
const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567";

// Encode provided Uint8Array using Base32.
function base32encode(view: Uint8Array): string {
  const length = view.length;
  const leftover = (length * 8) % 5;
  const offset = leftover === 0 ? 0 : 5 - leftover;

  let value = 0;
  let output = "";
  let bits = 0;

  for (var i = 0; i < length; i++) {
    value = (value << 8) | view[i];
    bits += 8;

    while (bits >= 5) {
      output += alphabet[(value >>> (bits + offset - 5)) & 31];
      bits -= 5;
    }
  }

  if (bits > 0) {
    output += alphabet[(value << (5 - (bits + offset))) & 31];
  }

  return output;
}

export function TOTPAuthenticatorItem(props: {
  spec: TOTPAuthenticator;
  onClick?: () => void;
}): JSX.Element {
  return <DeviceItem icon={mdiCellphone} name={props.spec.name} onClick={props.onClick} />;
}

export function TOTPAuthenticatorEnrollItem(props: { onClick?: () => void }): JSX.Element {
  return <DeviceItem icon={mdiCellphone} name="Neue App registrieren" onClick={props.onClick} />;
}

const digits = 6;

export function AddTOTPAuthenticator(props: {
  user: string;
  authRecoverCb: () => void;
}): JSX.Element {
  const { user, authRecoverCb } = props;
  const [code, setCode] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState<string | null>(null);
  const { branding } = useTheme();

  const secret = useMemo(() => {
    const s = new Uint8Array(20);
    crypto.getRandomValues(s);
    return s;
  }, []);

  const otpUrl = useMemo(() => {
    const params = new URLSearchParams();
    params.set("issuer", branding?.name ?? "");
    params.set("algorithm", "SHA1");
    params.set("digits", digits.toString(10));
    params.set("period", "30");
    params.set("secret", base32encode(secret));
    return (
      "otpauth://totp/" +
      encodeURIComponent(branding?.name ?? "") +
      ":" +
      encodeURIComponent(user) +
      "?" +
      params.toString()
    );
  }, [user, secret, branding]);
  const onNext = useCallback(async () => {
    if (name.trim() === "") {
      setNameError("Bitte gib dem Gerät einen Namen");
      return;
    } else {
      setNameError(null);
    }
    try {
      setError(null);
      const id = new Uint8Array(8);
      crypto.getRandomValues(id);
      const res = await idpc.AddTOTPAuthenticator({
        code: parseInt(code),
        authenticator: { name, digits, secret, id },
      });
      setCode("");
      authRecoverCb();
    } catch (err) {
      setError(err.toString());
    }
  }, [code, secret, name, authRecoverCb]);

  return (
    <Container>
      <Input name="Namen des Geräts" type="text" value={name} onChange={setName} />
      <ErrorMessage error={nameError} />
      <Prompt>Bitte scannen Sie den folgenden QR-Code mit ihrer Authenticator-App:</Prompt>
      <QRContainer>
        <QRCode value={otpUrl} size={245} />
      </QRContainer>
      <Prompt>
        Wenn Sie die App eingerichtet haben, geben Sie bitte hier den angezeigten Code ein:
      </Prompt>
      <Input
        name="Sicherheitscode"
        type="text"
        inputMode="numeric"
        value={code}
        onChange={setCode}
      />
      <ErrorMessage error={error} />
      <Button onClick={onNext}>Hinzufügen</Button>
    </Container>
  );
}

export function TOTP(props: {
  spec: TOTPAuthenticator;
  authCtx?: AuthContext;
  authContinue: (c: AuthContinue) => void;
  authRecover: () => void;
}): JSX.Element {
  const theme = useTheme();
  const [code, setCode] = useState("");
  const [result, setResult] = useState<AuthTOTPResponse_Result | null>(null);
  const [error, setError] = useState<string | null>(null);

  const onNext = useCallback(async () => {
    try {
      setError(null);
      setResult(null);
      const res = await idpc.AuthTOTP({
        code: parseInt(code),
        authenticatorId: props.spec.id,
        authCtx: props.authCtx,
      });
      if (res.result === AuthTOTPResponse_Result.SUCCESS) {
        props.authContinue(res.authContinue!);
      }
      setResult(res.result);
      setCode("");
    } catch (err) {
      if (err.code === grpc.Code.Unauthenticated || err.code === grpc.Code.FailedPrecondition) {
        props.authRecover();
      }
      setError(err.toString());
    }
  }, [code, props]);

  let displayError: string | null = null;
  if (error !== null) {
    displayError = "Bitte versuchen Sie es später nochmals. Details: " + error;
  } else {
    switch (result) {
      case AuthTOTPResponse_Result.WRONG_CODE:
        displayError = "Falscher Code.";
        break;
      case AuthTOTPResponse_Result.ALREADY_USED:
        displayError =
          "Dieser Code wurde schon benutzt. Bitte warten Sie, bis ihr Gerät den nächsten Code anzeigt.";
        break;
      case AuthTOTPResponse_Result.LOCKED_OUT:
        return (
          <ErrorLayout
            icon={mdiKeyRemove}
            primaryMessage="Es wurden zu viele fehlgeschlagene Anmeldungen mit diesem Mechanismus gemacht. Dies deutet auf einen Angriff auf Ihren Account hin. Aus Sicherheitsgründen wurde der Mechanismus deaktiviert."
            secondaryMessage={theme.branding?.supportContactText}
          />
        );
      case AuthTOTPResponse_Result.RATE_LIMITED:
        return (
          <ErrorLayout
            icon={mdiTimerSand}
            primaryMessage="Sie haben in zu viele fehlgeschlagene Versuche in den letzten paar Minuten. Bitte probieren Sie es später wieder."
            secondaryMessage="Nächster Versuch in 5min"
          />
        );
    }
  }

  return (
    <Container>
      <TOTPAuthenticatorItem spec={props.spec} />
      <Prompt>
        Bitte geben Sie den Sicherheitscode an, der auf "{props.spec.name}" angezeigt wird:
      </Prompt>
      <Input
        name="Sicherheitscode"
        type="text"
        inputMode="numeric"
        value={code}
        autoFocus
        onChange={setCode}
        onEnter={onNext}
      />
      <ErrorMessage error={displayError} />
      <Button onClick={onNext}>Weiter</Button>
    </Container>
  );
}
