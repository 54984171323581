
import styled from "styled-components";
import React, { useState } from "react";
import { nanoid } from "./nanoid";

export function Checkbox(props: {
  initialValue?: boolean;
  children: React.ReactNode;
  onChange?: (newValue: boolean) => void;
}) {
  const [checked, setChecked] = useState(props.initialValue ?? false);
  const [id] = useState(nanoid(10));

  return (
    <div>
      <input type="checkbox" id={id} checked={checked} onChange={() => { setChecked(old => !old); props.onChange?.(!checked)}} />
      &nbsp;
      <label htmlFor={id} >{props.children}</label>
    </div>
  );
}
