/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { messageTypeRegistry } from "../../../typeRegistry";
import { ClientProfile } from "../idp";

export const protobufPackage = "idp.oauth2";

export enum ClientType {
  UNKNOWN_CLIENT = 0,
  CONFIDENTIAL_CLIENT = 1,
  PUBLIC_CLIENT = 2,
  SERVICE_ACCOUNT = 3,
  UNRECOGNIZED = -1,
}

export enum SubjectType {
  SUBJECT_PUBLIC = 0,
  SUBJECT_PAIRWISE = 1,
  UNRECOGNIZED = -1,
}

export enum ResponseType {
  RESPONSE_TYPE_UNKNOWN = 0,
  RESPONSE_TYPE_CODE = 1,
  RESPONSE_TYPE_ID_TOKEN = 2,
  RESPONSE_TYPE_TOKEN = 3,
  RESPONSE_TYPE_NONE = 4,
  UNRECOGNIZED = -1,
}

export enum PKCEChallengeMethod {
  PKCE_METHOD_NONE = 0,
  PKCE_METHOD_PLAIN = 1,
  PKCE_METHOD_S256 = 2,
  UNRECOGNIZED = -1,
}

/** OAuth2/OIDC Client config */
export interface Client {
  $type: "idp.oauth2.Client";
  id: string;
  redirectUri: string[];
  /** If this is set, implicit and PKCE flows are not allowed */
  secret: string;
  secretHash: Uint8Array;
  /**
   * The client is allowed to request the following scopes without
   * prompting the user for consent.
   */
  autoConsentScope: string[];
  /**
   * The client is allowed to request the following scopes for itself (without
   * being attached to a user). Only OAuth2 scopes are allowed here as there is
   * no identity to fullfill OIDC scopes.
   */
  selfScopes: string[];
  subjectType: SubjectType;
  /**
   * List of claim names which should be included in the ID token. These will be
   * included in addition to any the client might request through the claims
   * parameter. They are however only included if the client has access to them,
   * i.e. if they are available to this client on the userinfo endpoint.
   */
  extraIdTokenClaims: string[];
  /** Only issue when user is a member of any of these groups */
  restrictToGroup: string[];
  /** Only issue when authentication fulfills any of these classes */
  restrictToAcr: string[];
  /** Allow the OAuth 2.0 implicit flow. Not recommended for new clients. */
  allowImplicit: boolean;
  /**
   * Enables or disables App Tokens, an interopatibility feature to work with
   * protocols and systems still dependant on legacy passwords.
   */
  enableApptokens: boolean;
  profile: ClientProfile | undefined;
}

export interface ClaimRequest {
  $type: "idp.oauth2.ClaimRequest";
  name: string;
  essential: boolean;
  inUserinfo: boolean;
  inIdToken: boolean;
  value: string[];
  locale: string;
}

export interface AuthorizeRequest {
  $type: "idp.oauth2.AuthorizeRequest";
  clientId: string;
  scope: string[];
  redirectUri: string;
  nonce: string;
  maxAge: number;
  requestedAcr: string[];
  responseType: ResponseType[];
  claimsLocale: string[];
  codeChallenge: string;
  codeChallengeMethod: PKCEChallengeMethod;
  claimRequest: ClaimRequest[];
  consentScopes: string[];
}

export interface AuthorizeError {
  $type: "idp.oauth2.AuthorizeError";
  error: string;
  errorDescription: string;
  errorUri: string;
}

export interface AuthorizeRequireConsent {
  $type: "idp.oauth2.AuthorizeRequireConsent";
  scopes: string[];
}

export interface AuthorizeData {
  $type: "idp.oauth2.AuthorizeData";
  code: string;
  accessToken: string;
  tokenType: string;
  expiresIn: number;
  idToken: string;
  sessionState: string;
  scope: string[];
}

export interface AuthorizeResponse {
  $type: "idp.oauth2.AuthorizeResponse";
  type?:
    | { $case: "data"; data: AuthorizeData }
    | { $case: "requireConsent"; requireConsent: AuthorizeRequireConsent }
    | { $case: "error"; error: AuthorizeError }
    | undefined;
  clientProfile: ClientProfile | undefined;
}

export interface RevokeRequest {
  $type: "idp.oauth2.RevokeRequest";
  clientId: string;
}

export interface RevokeResponse {
  $type: "idp.oauth2.RevokeResponse";
}

export interface Consent {
  $type: "idp.oauth2.Consent";
  clientId: string;
  subject: string;
  scope: string[];
}

export interface GetConsentRequest {
  $type: "idp.oauth2.GetConsentRequest";
}

export interface GetConsentResponse {
  $type: "idp.oauth2.GetConsentResponse";
  consent: Consent[];
}

export interface GetClientProfileRequest {
  $type: "idp.oauth2.GetClientProfileRequest";
  clientId: string;
}

export interface ReplaceClientRequest {
  $type: "idp.oauth2.ReplaceClientRequest";
  newClient: Client | undefined;
  oldClientId: string;
}

export interface ReplaceClientResponse {
  $type: "idp.oauth2.ReplaceClientResponse";
  clientId: string;
}

export interface ListClientsRequest {
  $type: "idp.oauth2.ListClientsRequest";
}

export interface ListClientsResponse {
  $type: "idp.oauth2.ListClientsResponse";
  client: Client[];
}

export interface RevokeClientRequest {
  $type: "idp.oauth2.RevokeClientRequest";
  clientId: string;
}

export interface RevokeClientResponse {
  $type: "idp.oauth2.RevokeClientResponse";
}

function createBaseClient(): Client {
  return {
    $type: "idp.oauth2.Client",
    id: "",
    redirectUri: [],
    secret: "",
    secretHash: new Uint8Array(0),
    autoConsentScope: [],
    selfScopes: [],
    subjectType: 0,
    extraIdTokenClaims: [],
    restrictToGroup: [],
    restrictToAcr: [],
    allowImplicit: false,
    enableApptokens: false,
    profile: undefined,
  };
}

export const Client = {
  $type: "idp.oauth2.Client" as const,

  encode(message: Client, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    for (const v of message.redirectUri) {
      writer.uint32(18).string(v!);
    }
    if (message.secret !== "") {
      writer.uint32(26).string(message.secret);
    }
    if (message.secretHash.length !== 0) {
      writer.uint32(42).bytes(message.secretHash);
    }
    for (const v of message.autoConsentScope) {
      writer.uint32(66).string(v!);
    }
    for (const v of message.selfScopes) {
      writer.uint32(82).string(v!);
    }
    if (message.subjectType !== 0) {
      writer.uint32(96).int32(message.subjectType);
    }
    for (const v of message.extraIdTokenClaims) {
      writer.uint32(106).string(v!);
    }
    for (const v of message.restrictToGroup) {
      writer.uint32(58).string(v!);
    }
    for (const v of message.restrictToAcr) {
      writer.uint32(74).string(v!);
    }
    if (message.allowImplicit === true) {
      writer.uint32(88).bool(message.allowImplicit);
    }
    if (message.enableApptokens === true) {
      writer.uint32(112).bool(message.enableApptokens);
    }
    if (message.profile !== undefined) {
      ClientProfile.encode(message.profile, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Client {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClient();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.redirectUri.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.secret = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.secretHash = reader.bytes();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.autoConsentScope.push(reader.string());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.selfScopes.push(reader.string());
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.subjectType = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.extraIdTokenClaims.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.restrictToGroup.push(reader.string());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.restrictToAcr.push(reader.string());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.allowImplicit = reader.bool();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.enableApptokens = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.profile = ClientProfile.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Client>, I>>(base?: I): Client {
    return Client.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Client>, I>>(object: I): Client {
    const message = createBaseClient();
    message.id = object.id ?? "";
    message.redirectUri = object.redirectUri?.map((e) => e) || [];
    message.secret = object.secret ?? "";
    message.secretHash = object.secretHash ?? new Uint8Array(0);
    message.autoConsentScope = object.autoConsentScope?.map((e) => e) || [];
    message.selfScopes = object.selfScopes?.map((e) => e) || [];
    message.subjectType = object.subjectType ?? 0;
    message.extraIdTokenClaims = object.extraIdTokenClaims?.map((e) => e) || [];
    message.restrictToGroup = object.restrictToGroup?.map((e) => e) || [];
    message.restrictToAcr = object.restrictToAcr?.map((e) => e) || [];
    message.allowImplicit = object.allowImplicit ?? false;
    message.enableApptokens = object.enableApptokens ?? false;
    message.profile = (object.profile !== undefined && object.profile !== null)
      ? ClientProfile.fromPartial(object.profile)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(Client.$type, Client);

function createBaseClaimRequest(): ClaimRequest {
  return {
    $type: "idp.oauth2.ClaimRequest",
    name: "",
    essential: false,
    inUserinfo: false,
    inIdToken: false,
    value: [],
    locale: "",
  };
}

export const ClaimRequest = {
  $type: "idp.oauth2.ClaimRequest" as const,

  encode(message: ClaimRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.essential === true) {
      writer.uint32(16).bool(message.essential);
    }
    if (message.inUserinfo === true) {
      writer.uint32(40).bool(message.inUserinfo);
    }
    if (message.inIdToken === true) {
      writer.uint32(48).bool(message.inIdToken);
    }
    for (const v of message.value) {
      writer.uint32(26).string(v!);
    }
    if (message.locale !== "") {
      writer.uint32(34).string(message.locale);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClaimRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClaimRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.essential = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.inUserinfo = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.inIdToken = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.value.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.locale = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ClaimRequest>, I>>(base?: I): ClaimRequest {
    return ClaimRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClaimRequest>, I>>(object: I): ClaimRequest {
    const message = createBaseClaimRequest();
    message.name = object.name ?? "";
    message.essential = object.essential ?? false;
    message.inUserinfo = object.inUserinfo ?? false;
    message.inIdToken = object.inIdToken ?? false;
    message.value = object.value?.map((e) => e) || [];
    message.locale = object.locale ?? "";
    return message;
  },
};

messageTypeRegistry.set(ClaimRequest.$type, ClaimRequest);

function createBaseAuthorizeRequest(): AuthorizeRequest {
  return {
    $type: "idp.oauth2.AuthorizeRequest",
    clientId: "",
    scope: [],
    redirectUri: "",
    nonce: "",
    maxAge: 0,
    requestedAcr: [],
    responseType: [],
    claimsLocale: [],
    codeChallenge: "",
    codeChallengeMethod: 0,
    claimRequest: [],
    consentScopes: [],
  };
}

export const AuthorizeRequest = {
  $type: "idp.oauth2.AuthorizeRequest" as const,

  encode(message: AuthorizeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== "") {
      writer.uint32(10).string(message.clientId);
    }
    for (const v of message.scope) {
      writer.uint32(90).string(v!);
    }
    if (message.redirectUri !== "") {
      writer.uint32(18).string(message.redirectUri);
    }
    if (message.nonce !== "") {
      writer.uint32(26).string(message.nonce);
    }
    if (message.maxAge !== 0) {
      writer.uint32(32).uint32(message.maxAge);
    }
    for (const v of message.requestedAcr) {
      writer.uint32(42).string(v!);
    }
    writer.uint32(50).fork();
    for (const v of message.responseType) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.claimsLocale) {
      writer.uint32(58).string(v!);
    }
    if (message.codeChallenge !== "") {
      writer.uint32(66).string(message.codeChallenge);
    }
    if (message.codeChallengeMethod !== 0) {
      writer.uint32(72).int32(message.codeChallengeMethod);
    }
    for (const v of message.claimRequest) {
      ClaimRequest.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.consentScopes) {
      writer.uint32(82).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthorizeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthorizeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clientId = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.scope.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.redirectUri = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.nonce = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.maxAge = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.requestedAcr.push(reader.string());
          continue;
        case 6:
          if (tag === 48) {
            message.responseType.push(reader.int32() as any);

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.responseType.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.claimsLocale.push(reader.string());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.codeChallenge = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.codeChallengeMethod = reader.int32() as any;
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.claimRequest.push(ClaimRequest.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.consentScopes.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthorizeRequest>, I>>(base?: I): AuthorizeRequest {
    return AuthorizeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthorizeRequest>, I>>(object: I): AuthorizeRequest {
    const message = createBaseAuthorizeRequest();
    message.clientId = object.clientId ?? "";
    message.scope = object.scope?.map((e) => e) || [];
    message.redirectUri = object.redirectUri ?? "";
    message.nonce = object.nonce ?? "";
    message.maxAge = object.maxAge ?? 0;
    message.requestedAcr = object.requestedAcr?.map((e) => e) || [];
    message.responseType = object.responseType?.map((e) => e) || [];
    message.claimsLocale = object.claimsLocale?.map((e) => e) || [];
    message.codeChallenge = object.codeChallenge ?? "";
    message.codeChallengeMethod = object.codeChallengeMethod ?? 0;
    message.claimRequest = object.claimRequest?.map((e) => ClaimRequest.fromPartial(e)) || [];
    message.consentScopes = object.consentScopes?.map((e) => e) || [];
    return message;
  },
};

messageTypeRegistry.set(AuthorizeRequest.$type, AuthorizeRequest);

function createBaseAuthorizeError(): AuthorizeError {
  return { $type: "idp.oauth2.AuthorizeError", error: "", errorDescription: "", errorUri: "" };
}

export const AuthorizeError = {
  $type: "idp.oauth2.AuthorizeError" as const,

  encode(message: AuthorizeError, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.error !== "") {
      writer.uint32(10).string(message.error);
    }
    if (message.errorDescription !== "") {
      writer.uint32(18).string(message.errorDescription);
    }
    if (message.errorUri !== "") {
      writer.uint32(26).string(message.errorUri);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthorizeError {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthorizeError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.error = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.errorDescription = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.errorUri = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthorizeError>, I>>(base?: I): AuthorizeError {
    return AuthorizeError.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthorizeError>, I>>(object: I): AuthorizeError {
    const message = createBaseAuthorizeError();
    message.error = object.error ?? "";
    message.errorDescription = object.errorDescription ?? "";
    message.errorUri = object.errorUri ?? "";
    return message;
  },
};

messageTypeRegistry.set(AuthorizeError.$type, AuthorizeError);

function createBaseAuthorizeRequireConsent(): AuthorizeRequireConsent {
  return { $type: "idp.oauth2.AuthorizeRequireConsent", scopes: [] };
}

export const AuthorizeRequireConsent = {
  $type: "idp.oauth2.AuthorizeRequireConsent" as const,

  encode(message: AuthorizeRequireConsent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.scopes) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthorizeRequireConsent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthorizeRequireConsent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scopes.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthorizeRequireConsent>, I>>(base?: I): AuthorizeRequireConsent {
    return AuthorizeRequireConsent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthorizeRequireConsent>, I>>(object: I): AuthorizeRequireConsent {
    const message = createBaseAuthorizeRequireConsent();
    message.scopes = object.scopes?.map((e) => e) || [];
    return message;
  },
};

messageTypeRegistry.set(AuthorizeRequireConsent.$type, AuthorizeRequireConsent);

function createBaseAuthorizeData(): AuthorizeData {
  return {
    $type: "idp.oauth2.AuthorizeData",
    code: "",
    accessToken: "",
    tokenType: "",
    expiresIn: 0,
    idToken: "",
    sessionState: "",
    scope: [],
  };
}

export const AuthorizeData = {
  $type: "idp.oauth2.AuthorizeData" as const,

  encode(message: AuthorizeData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== "") {
      writer.uint32(10).string(message.code);
    }
    if (message.accessToken !== "") {
      writer.uint32(18).string(message.accessToken);
    }
    if (message.tokenType !== "") {
      writer.uint32(66).string(message.tokenType);
    }
    if (message.expiresIn !== 0) {
      writer.uint32(32).uint32(message.expiresIn);
    }
    if (message.idToken !== "") {
      writer.uint32(42).string(message.idToken);
    }
    if (message.sessionState !== "") {
      writer.uint32(50).string(message.sessionState);
    }
    for (const v of message.scope) {
      writer.uint32(58).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthorizeData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthorizeData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accessToken = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.tokenType = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.expiresIn = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.idToken = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.sessionState = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.scope.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthorizeData>, I>>(base?: I): AuthorizeData {
    return AuthorizeData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthorizeData>, I>>(object: I): AuthorizeData {
    const message = createBaseAuthorizeData();
    message.code = object.code ?? "";
    message.accessToken = object.accessToken ?? "";
    message.tokenType = object.tokenType ?? "";
    message.expiresIn = object.expiresIn ?? 0;
    message.idToken = object.idToken ?? "";
    message.sessionState = object.sessionState ?? "";
    message.scope = object.scope?.map((e) => e) || [];
    return message;
  },
};

messageTypeRegistry.set(AuthorizeData.$type, AuthorizeData);

function createBaseAuthorizeResponse(): AuthorizeResponse {
  return { $type: "idp.oauth2.AuthorizeResponse", type: undefined, clientProfile: undefined };
}

export const AuthorizeResponse = {
  $type: "idp.oauth2.AuthorizeResponse" as const,

  encode(message: AuthorizeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.type?.$case) {
      case "data":
        AuthorizeData.encode(message.type.data, writer.uint32(10).fork()).ldelim();
        break;
      case "requireConsent":
        AuthorizeRequireConsent.encode(message.type.requireConsent, writer.uint32(18).fork()).ldelim();
        break;
      case "error":
        AuthorizeError.encode(message.type.error, writer.uint32(26).fork()).ldelim();
        break;
    }
    if (message.clientProfile !== undefined) {
      ClientProfile.encode(message.clientProfile, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthorizeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthorizeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = { $case: "data", data: AuthorizeData.decode(reader, reader.uint32()) };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.type = {
            $case: "requireConsent",
            requireConsent: AuthorizeRequireConsent.decode(reader, reader.uint32()),
          };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.type = { $case: "error", error: AuthorizeError.decode(reader, reader.uint32()) };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.clientProfile = ClientProfile.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthorizeResponse>, I>>(base?: I): AuthorizeResponse {
    return AuthorizeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthorizeResponse>, I>>(object: I): AuthorizeResponse {
    const message = createBaseAuthorizeResponse();
    if (object.type?.$case === "data" && object.type?.data !== undefined && object.type?.data !== null) {
      message.type = { $case: "data", data: AuthorizeData.fromPartial(object.type.data) };
    }
    if (
      object.type?.$case === "requireConsent" &&
      object.type?.requireConsent !== undefined &&
      object.type?.requireConsent !== null
    ) {
      message.type = {
        $case: "requireConsent",
        requireConsent: AuthorizeRequireConsent.fromPartial(object.type.requireConsent),
      };
    }
    if (object.type?.$case === "error" && object.type?.error !== undefined && object.type?.error !== null) {
      message.type = { $case: "error", error: AuthorizeError.fromPartial(object.type.error) };
    }
    message.clientProfile = (object.clientProfile !== undefined && object.clientProfile !== null)
      ? ClientProfile.fromPartial(object.clientProfile)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(AuthorizeResponse.$type, AuthorizeResponse);

function createBaseRevokeRequest(): RevokeRequest {
  return { $type: "idp.oauth2.RevokeRequest", clientId: "" };
}

export const RevokeRequest = {
  $type: "idp.oauth2.RevokeRequest" as const,

  encode(message: RevokeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== "") {
      writer.uint32(10).string(message.clientId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RevokeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRevokeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clientId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RevokeRequest>, I>>(base?: I): RevokeRequest {
    return RevokeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RevokeRequest>, I>>(object: I): RevokeRequest {
    const message = createBaseRevokeRequest();
    message.clientId = object.clientId ?? "";
    return message;
  },
};

messageTypeRegistry.set(RevokeRequest.$type, RevokeRequest);

function createBaseRevokeResponse(): RevokeResponse {
  return { $type: "idp.oauth2.RevokeResponse" };
}

export const RevokeResponse = {
  $type: "idp.oauth2.RevokeResponse" as const,

  encode(_: RevokeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RevokeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRevokeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RevokeResponse>, I>>(base?: I): RevokeResponse {
    return RevokeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RevokeResponse>, I>>(_: I): RevokeResponse {
    const message = createBaseRevokeResponse();
    return message;
  },
};

messageTypeRegistry.set(RevokeResponse.$type, RevokeResponse);

function createBaseConsent(): Consent {
  return { $type: "idp.oauth2.Consent", clientId: "", subject: "", scope: [] };
}

export const Consent = {
  $type: "idp.oauth2.Consent" as const,

  encode(message: Consent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== "") {
      writer.uint32(18).string(message.clientId);
    }
    if (message.subject !== "") {
      writer.uint32(26).string(message.subject);
    }
    for (const v of message.scope) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clientId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.subject = reader.string();
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scope.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Consent>, I>>(base?: I): Consent {
    return Consent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Consent>, I>>(object: I): Consent {
    const message = createBaseConsent();
    message.clientId = object.clientId ?? "";
    message.subject = object.subject ?? "";
    message.scope = object.scope?.map((e) => e) || [];
    return message;
  },
};

messageTypeRegistry.set(Consent.$type, Consent);

function createBaseGetConsentRequest(): GetConsentRequest {
  return { $type: "idp.oauth2.GetConsentRequest" };
}

export const GetConsentRequest = {
  $type: "idp.oauth2.GetConsentRequest" as const,

  encode(_: GetConsentRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetConsentRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetConsentRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetConsentRequest>, I>>(base?: I): GetConsentRequest {
    return GetConsentRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetConsentRequest>, I>>(_: I): GetConsentRequest {
    const message = createBaseGetConsentRequest();
    return message;
  },
};

messageTypeRegistry.set(GetConsentRequest.$type, GetConsentRequest);

function createBaseGetConsentResponse(): GetConsentResponse {
  return { $type: "idp.oauth2.GetConsentResponse", consent: [] };
}

export const GetConsentResponse = {
  $type: "idp.oauth2.GetConsentResponse" as const,

  encode(message: GetConsentResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.consent) {
      Consent.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetConsentResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetConsentResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.consent.push(Consent.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetConsentResponse>, I>>(base?: I): GetConsentResponse {
    return GetConsentResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetConsentResponse>, I>>(object: I): GetConsentResponse {
    const message = createBaseGetConsentResponse();
    message.consent = object.consent?.map((e) => Consent.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(GetConsentResponse.$type, GetConsentResponse);

function createBaseGetClientProfileRequest(): GetClientProfileRequest {
  return { $type: "idp.oauth2.GetClientProfileRequest", clientId: "" };
}

export const GetClientProfileRequest = {
  $type: "idp.oauth2.GetClientProfileRequest" as const,

  encode(message: GetClientProfileRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== "") {
      writer.uint32(10).string(message.clientId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetClientProfileRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetClientProfileRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clientId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetClientProfileRequest>, I>>(base?: I): GetClientProfileRequest {
    return GetClientProfileRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetClientProfileRequest>, I>>(object: I): GetClientProfileRequest {
    const message = createBaseGetClientProfileRequest();
    message.clientId = object.clientId ?? "";
    return message;
  },
};

messageTypeRegistry.set(GetClientProfileRequest.$type, GetClientProfileRequest);

function createBaseReplaceClientRequest(): ReplaceClientRequest {
  return { $type: "idp.oauth2.ReplaceClientRequest", newClient: undefined, oldClientId: "" };
}

export const ReplaceClientRequest = {
  $type: "idp.oauth2.ReplaceClientRequest" as const,

  encode(message: ReplaceClientRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.newClient !== undefined) {
      Client.encode(message.newClient, writer.uint32(10).fork()).ldelim();
    }
    if (message.oldClientId !== "") {
      writer.uint32(18).string(message.oldClientId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReplaceClientRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReplaceClientRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.newClient = Client.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.oldClientId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ReplaceClientRequest>, I>>(base?: I): ReplaceClientRequest {
    return ReplaceClientRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReplaceClientRequest>, I>>(object: I): ReplaceClientRequest {
    const message = createBaseReplaceClientRequest();
    message.newClient = (object.newClient !== undefined && object.newClient !== null)
      ? Client.fromPartial(object.newClient)
      : undefined;
    message.oldClientId = object.oldClientId ?? "";
    return message;
  },
};

messageTypeRegistry.set(ReplaceClientRequest.$type, ReplaceClientRequest);

function createBaseReplaceClientResponse(): ReplaceClientResponse {
  return { $type: "idp.oauth2.ReplaceClientResponse", clientId: "" };
}

export const ReplaceClientResponse = {
  $type: "idp.oauth2.ReplaceClientResponse" as const,

  encode(message: ReplaceClientResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== "") {
      writer.uint32(10).string(message.clientId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReplaceClientResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReplaceClientResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clientId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ReplaceClientResponse>, I>>(base?: I): ReplaceClientResponse {
    return ReplaceClientResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReplaceClientResponse>, I>>(object: I): ReplaceClientResponse {
    const message = createBaseReplaceClientResponse();
    message.clientId = object.clientId ?? "";
    return message;
  },
};

messageTypeRegistry.set(ReplaceClientResponse.$type, ReplaceClientResponse);

function createBaseListClientsRequest(): ListClientsRequest {
  return { $type: "idp.oauth2.ListClientsRequest" };
}

export const ListClientsRequest = {
  $type: "idp.oauth2.ListClientsRequest" as const,

  encode(_: ListClientsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListClientsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListClientsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ListClientsRequest>, I>>(base?: I): ListClientsRequest {
    return ListClientsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListClientsRequest>, I>>(_: I): ListClientsRequest {
    const message = createBaseListClientsRequest();
    return message;
  },
};

messageTypeRegistry.set(ListClientsRequest.$type, ListClientsRequest);

function createBaseListClientsResponse(): ListClientsResponse {
  return { $type: "idp.oauth2.ListClientsResponse", client: [] };
}

export const ListClientsResponse = {
  $type: "idp.oauth2.ListClientsResponse" as const,

  encode(message: ListClientsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.client) {
      Client.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListClientsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListClientsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.client.push(Client.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ListClientsResponse>, I>>(base?: I): ListClientsResponse {
    return ListClientsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListClientsResponse>, I>>(object: I): ListClientsResponse {
    const message = createBaseListClientsResponse();
    message.client = object.client?.map((e) => Client.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(ListClientsResponse.$type, ListClientsResponse);

function createBaseRevokeClientRequest(): RevokeClientRequest {
  return { $type: "idp.oauth2.RevokeClientRequest", clientId: "" };
}

export const RevokeClientRequest = {
  $type: "idp.oauth2.RevokeClientRequest" as const,

  encode(message: RevokeClientRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== "") {
      writer.uint32(10).string(message.clientId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RevokeClientRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRevokeClientRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clientId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RevokeClientRequest>, I>>(base?: I): RevokeClientRequest {
    return RevokeClientRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RevokeClientRequest>, I>>(object: I): RevokeClientRequest {
    const message = createBaseRevokeClientRequest();
    message.clientId = object.clientId ?? "";
    return message;
  },
};

messageTypeRegistry.set(RevokeClientRequest.$type, RevokeClientRequest);

function createBaseRevokeClientResponse(): RevokeClientResponse {
  return { $type: "idp.oauth2.RevokeClientResponse" };
}

export const RevokeClientResponse = {
  $type: "idp.oauth2.RevokeClientResponse" as const,

  encode(_: RevokeClientResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RevokeClientResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRevokeClientResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RevokeClientResponse>, I>>(base?: I): RevokeClientResponse {
    return RevokeClientResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RevokeClientResponse>, I>>(_: I): RevokeClientResponse {
    const message = createBaseRevokeClientResponse();
    return message;
  },
};

messageTypeRegistry.set(RevokeClientResponse.$type, RevokeClientResponse);

export interface OAuth2 {
  /** Authorize delegates IDP authentication via OAuth2/OIDC. */
  Authorize(request: DeepPartial<AuthorizeRequest>, metadata?: grpc.Metadata): Promise<AuthorizeResponse>;
  /** Revoke revokes all user-given consent and refresh tokens for a client. */
  Revoke(request: DeepPartial<RevokeRequest>, metadata?: grpc.Metadata): Promise<RevokeResponse>;
  /**
   * GetConsent returns a list of applications to which the user has granted
   * scopes.
   */
  GetConsent(request: DeepPartial<GetConsentRequest>, metadata?: grpc.Metadata): Promise<GetConsentResponse>;
  /** GetClientProfile gets the client profile of the given application */
  GetClientProfile(request: DeepPartial<GetClientProfileRequest>, metadata?: grpc.Metadata): Promise<ClientProfile>;
}

export class OAuth2ClientImpl implements OAuth2 {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.Authorize = this.Authorize.bind(this);
    this.Revoke = this.Revoke.bind(this);
    this.GetConsent = this.GetConsent.bind(this);
    this.GetClientProfile = this.GetClientProfile.bind(this);
  }

  Authorize(request: DeepPartial<AuthorizeRequest>, metadata?: grpc.Metadata): Promise<AuthorizeResponse> {
    return this.rpc.unary(OAuth2AuthorizeDesc, AuthorizeRequest.fromPartial(request), metadata);
  }

  Revoke(request: DeepPartial<RevokeRequest>, metadata?: grpc.Metadata): Promise<RevokeResponse> {
    return this.rpc.unary(OAuth2RevokeDesc, RevokeRequest.fromPartial(request), metadata);
  }

  GetConsent(request: DeepPartial<GetConsentRequest>, metadata?: grpc.Metadata): Promise<GetConsentResponse> {
    return this.rpc.unary(OAuth2GetConsentDesc, GetConsentRequest.fromPartial(request), metadata);
  }

  GetClientProfile(request: DeepPartial<GetClientProfileRequest>, metadata?: grpc.Metadata): Promise<ClientProfile> {
    return this.rpc.unary(OAuth2GetClientProfileDesc, GetClientProfileRequest.fromPartial(request), metadata);
  }
}

export const OAuth2Desc = { serviceName: "idp.oauth2.OAuth2" };

export const OAuth2AuthorizeDesc: UnaryMethodDefinitionish = {
  methodName: "Authorize",
  service: OAuth2Desc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AuthorizeRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AuthorizeResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OAuth2RevokeDesc: UnaryMethodDefinitionish = {
  methodName: "Revoke",
  service: OAuth2Desc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RevokeRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RevokeResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OAuth2GetConsentDesc: UnaryMethodDefinitionish = {
  methodName: "GetConsent",
  service: OAuth2Desc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetConsentRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetConsentResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OAuth2GetClientProfileDesc: UnaryMethodDefinitionish = {
  methodName: "GetClientProfile",
  service: OAuth2Desc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetClientProfileRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ClientProfile.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

/**
 * The OAuth2Admin service handles administrative operations for OAuth2
 * delegation. This service requires the id.dolansoft.org/oauth2 scope.
 */
export interface OAuth2Admin {
  /**
   * ReplaceClient creates, replaces or deletes a client. If old_client_id is
   * set to a non-existent client, a NotFound error is returned.
   */
  ReplaceClient(request: DeepPartial<ReplaceClientRequest>, metadata?: grpc.Metadata): Promise<ReplaceClientResponse>;
  /** ListClients lists all registered clients. */
  ListClients(request: DeepPartial<ListClientsRequest>, metadata?: grpc.Metadata): Promise<ListClientsResponse>;
  /**
   * RevokeClient revokes all persistent credentials and user-given scope
   * consent. This RPC should be used if it is believed the client or its data
   * have been compromised.
   */
  RevokeClient(request: DeepPartial<RevokeClientRequest>, metadata?: grpc.Metadata): Promise<RevokeClientResponse>;
}

export class OAuth2AdminClientImpl implements OAuth2Admin {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.ReplaceClient = this.ReplaceClient.bind(this);
    this.ListClients = this.ListClients.bind(this);
    this.RevokeClient = this.RevokeClient.bind(this);
  }

  ReplaceClient(request: DeepPartial<ReplaceClientRequest>, metadata?: grpc.Metadata): Promise<ReplaceClientResponse> {
    return this.rpc.unary(OAuth2AdminReplaceClientDesc, ReplaceClientRequest.fromPartial(request), metadata);
  }

  ListClients(request: DeepPartial<ListClientsRequest>, metadata?: grpc.Metadata): Promise<ListClientsResponse> {
    return this.rpc.unary(OAuth2AdminListClientsDesc, ListClientsRequest.fromPartial(request), metadata);
  }

  RevokeClient(request: DeepPartial<RevokeClientRequest>, metadata?: grpc.Metadata): Promise<RevokeClientResponse> {
    return this.rpc.unary(OAuth2AdminRevokeClientDesc, RevokeClientRequest.fromPartial(request), metadata);
  }
}

export const OAuth2AdminDesc = { serviceName: "idp.oauth2.OAuth2Admin" };

export const OAuth2AdminReplaceClientDesc: UnaryMethodDefinitionish = {
  methodName: "ReplaceClient",
  service: OAuth2AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ReplaceClientRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ReplaceClientResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OAuth2AdminListClientsDesc: UnaryMethodDefinitionish = {
  methodName: "ListClients",
  service: OAuth2AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListClientsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListClientsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OAuth2AdminRevokeClientDesc: UnaryMethodDefinitionish = {
  methodName: "RevokeClient",
  service: OAuth2AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RevokeClientRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RevokeClientResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P> | "$type">]: never };

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
