import { grpc } from "@improbable-eng/grpc-web";
import { mdiAlert, mdiKeyRemove, mdiRefreshCircle, mdiShieldAlert, mdiTimerSand } from "@mdi/js";
import Icon from "@mdi/react";
import { darken, lighten } from "polished";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components/macro";
import { idpc } from "../api";
import { Button } from "../Button";
import { ErrorMessage } from "../ErrorMessage";
import {
  AuthContext,
  AuthContinue,
  AuthPasswordResponse_Result,
  EmailAddress,
  GeneratedPasswordFamily,
  Mechanism,
  PasswordStrengthResponse,
  SendAuthMailRequest_Type,
} from "../generated/idp/api/idp";
import { Input } from "../Input";
import { StrengthBar, StrengthBarData } from "../StrengthBar";
import { ErrorLayout } from "./ErrorLayout";
import { EmailAddressItem } from "./Mail";

const Prompt = styled.div<{ warning?: boolean }>`
  color: ${(p) => (p.warning ? "#F16625" : "#505050")};
`;

const TopIconContainer = styled.div`
  margin-right: 5px;
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SuggestionsLayout = styled.div`
  display: flex;
`;

const AlignedList = styled.ul<{ width: number }>`
  padding-left: 20px;
  margin-top: 0;
  margin-bottom: 0;
  width: ${(p) => p.width}px;
  user-select: none;
  font-family: monospace;
  font-size: 14px;
`;

const SuggestionText = styled.div`
  color: #666;
  margin-top: 20px;
`;

const PromptIconLayout = styled.div`
  display: flex;
  align-items: center;
`;

const SuggestionHeader = styled.div`
  display: flex;
`;

const MinimalButton = styled.button`
  border: none;
  outline: none;
  background: none;
  :hover {
    color: ${(p) => lighten(0.2, "black")};
  }
  :active {
    transform: matrix(0.95, 0, 0, 0.95, 0, 0);
    color: ${(p) => darken(0.05, "black")};
  }
`;

function SuggestedPasswords(): JSX.Element {
  const [shortPasswords, setShortPasswords] = useState<string[] | null>(null);
  const [passphrases, setPassphrases] = useState<string[] | null>(null);

  const [generation, setGeneration] = useState(0);
  useEffect(() => {
    let ignore = false;
    async function fetchData() {
      try {
        const res = await idpc.GeneratePassword({
          count: 3,
          family: GeneratedPasswordFamily.PASSPHRASE,
        });
        if (!ignore) setPassphrases(res.password);
      } catch (err) {
        // TODO: Retry on error
        if (!ignore) setPassphrases(null);
      }
    }
    fetchData();
    return () => {
      ignore = true;
    };
  }, [generation]);
  useEffect(() => {
    let ignore = false;
    async function fetchData() {
      try {
        const res = await idpc.GeneratePassword({
          count: 3,
          family: GeneratedPasswordFamily.SHORT,
        });
        if (!ignore) setShortPasswords(res.password);
      } catch (err) {
        // TODO: Retry on error
        if (!ignore) setShortPasswords(null);
      }
    }
    fetchData();
    return () => {
      ignore = true;
    };
  }, [generation]);

  const refreshCb = useCallback(() => setGeneration((g) => g + 1), []);
  return (
    <>
      <SuggestionHeader>
        <h3>Vorschläge</h3>
        <MinimalButton onClick={refreshCb}>
          <Icon path={mdiRefreshCircle} size={1} />
        </MinimalButton>
      </SuggestionHeader>
      <SuggestionsLayout>
        <AlignedList width={250}>
          {passphrases?.map((p) => (
            <li key={p}>{p}</li>
          ))}
        </AlignedList>
        <AlignedList width={110}>
          {shortPasswords?.map((p) => (
            <li key={p}>{p}</li>
          ))}
        </AlignedList>
      </SuggestionsLayout>
      <SuggestionText>
        Oberhalb hat es ein paar Vorschläge für akzeptable Passwörter. Wenn Sie kein eigenes
        ausdenken wollen, können Sie eines davon nehmen. Bitte tippen Sie das gewünschte Passwort
        ab, kopieren ist deaktiviert.
      </SuggestionText>
    </>
  );
}

function responseToStrengthBar(res: PasswordStrengthResponse | null): StrengthBarData {
  if (!res?.policy) {
    return {
      color: "gray",
      text: "",
      strength: 0,
    };
  }
  // Log scale (in bits) between 8 bits and the upper bound of the Good category.
  const strength = Math.min(Math.max(res.strengthBits - 8, 0) / res.policy.goodUpperBound, 1);
  if (res.strengthBits < res.policy.tooSimpleUpperBound) {
    return {
      color: "red",
      text: "Zu einfach",
      strength,
    };
  }
  if (res.strengthBits < res.policy.acceptableUpperBound) {
    return {
      color: "lightgreen",
      text: "Akzeptabel",
      strength,
    };
  }
  if (res.strengthBits < res.policy.goodUpperBound) {
    return {
      color: "green",
      text: "Gut",
      strength,
    };
  }
  return {
    color: "darkgreen",
    text: "Stark",
    strength,
  };
}

export enum AuthPasswordExtension {
  UserRequested = 1,
  Reset = 2,
}

export function ChangePassword(props: {
  oldPassword?: string;
  authRecover: () => void;
  reason: AuthPasswordResponse_Result | AuthPasswordExtension;
}): JSX.Element {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [oldPassword, setOldPassword] = useState(props.oldPassword ?? "");

  const [strength, setStrength] = useState<PasswordStrengthResponse | null>(null);
  const [strengthUnsupported, setStrengthUnsupported] = useState(false);

  const [error, setError] = useState<string | null>(null);

  const onNext = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      if (password !== password2) {
        setError("Passwörter stimmen nicht überein");
        return;
      }
      if (!strengthUnsupported && !strength?.acceptable) {
        if (strength?.breached) {
          setError(
            "Das Passwort ist in einer öffentlichen Passwortliste. Bitte wählen Sie ein anderes."
          );
          return;
        }
        setError("Das Passwort ist zu einfach. Bitte wählen Sie ein komplexeres.");
        return;
      }
      try {
        setError(null);
        await idpc.ChangePassword({
          oldPassword: oldPassword,
          newPassword: password,
        });
        setPassword("");
        setPassword2("");
        props.authRecover();
      } catch (err) {
        setError(err.toString());
      }
    },
    [
      oldPassword,
      password,
      password2,
      props,
      strength?.acceptable,
      strength?.breached,
      strengthUnsupported,
    ]
  );

  useEffect(() => {
    let ignore = false;
    if (strengthUnsupported) return;
    async function fetchData() {
      try {
        const res = await idpc.PasswordStrength({ password: password });
        if (!ignore) setStrength(res);
      } catch (err) {
        if (err.code === grpc.Code.FailedPrecondition) {
          if (!ignore) setStrengthUnsupported(true);
        }
        if (!ignore) setStrength(null);
      }
    }
    fetchData();
    return () => {
      ignore = true;
    };
  }, [password, strengthUnsupported]);

  const strengthData = useMemo(() => responseToStrengthBar(strength), [strength]);

  let reasonText: JSX.Element;
  switch (props.reason) {
    case AuthPasswordExtension.UserRequested:
      reasonText = (
        <Prompt>Bitte geben Sie Ihr altes Passwort ein, um Ihr Passwort zu ändern.</Prompt>
      );
      break;
    case AuthPasswordExtension.Reset:
      reasonText = (
        <Prompt>
          Passwort-Rücksetzungsanforderung erfolgreich. Bitte wählen Sie ein neues Passwort.
        </Prompt>
      );
      break;
    case AuthPasswordResponse_Result.MUST_CHANGE_EXPOSED:
      reasonText = (
        <PromptIconLayout>
          <TopIconContainer>
            <Icon color="#F16625" path={mdiShieldAlert} size={2} />
          </TopIconContainer>
          <Prompt>
            Ihr aktuelles Passwort ist in einer öffentlichen Passwortliste. Bitte ändern Sie es.
          </Prompt>
        </PromptIconLayout>
      );
      break;
    case AuthPasswordResponse_Result.MUST_CHANGE_POLICY:
      reasonText = <Prompt>Die Passwortrichtlinen erfordern dass Sie Ihr Passwort ändern:</Prompt>;
      break;
    default:
      reasonText = <></>;
  }

  const needsOldPassword = props.oldPassword === undefined;

  return (
    <form onSubmit={onNext}>
      {reasonText}
      {needsOldPassword ?? false ? (
        <Input
          name="Altes Password"
          type="password"
          autoFocus
          autoComplete="current-password"
          value={oldPassword}
          onChange={setOldPassword}
        />
      ) : null}
      <Input
        name="Neues Passwort"
        type="password"
        autoFocus={!needsOldPassword}
        autoComplete="new-password"
        value={password}
        onChange={setPassword}
      />
      <Input
        name="Neues Passwort wiederholen"
        type="password"
        autoComplete="new-password"
        value={password2}
        onChange={setPassword2}
      />
      {!strengthUnsupported ? <StrengthBar {...strengthData} /> : null}
      <ErrorMessage error={error} />
      <ButtonBox>
        <Button type="submit">Weiter</Button>
      </ButtonBox>
      <SuggestedPasswords />
    </form>
  );
}

export function ResetPassword(props: {
  emailAddresses: EmailAddress[];
  authRecover: () => void;
}): JSX.Element {
  const { emailAddresses, authRecover } = props;
  const [code, setCode] = useState("");
  const [displayError, setDisplayError] = useState<string | null>(null);

  const onCodeSubmit = useCallback(async () => {
    try {
      await idpc.AuthBackchannel({
        code: parseInt(code.replace(/\D/g, ""), 10),
      });
      setIsChanging(true);
    } catch (err) {
      switch (err.code) {
        case grpc.Code.Aborted:
          setDisplayError("Session-User ungültig. Bitte Browser neu starten und erneut probieren.");
          setTimeout(authRecover, 5000);
          break;
        case grpc.Code.NotFound:
          setDisplayError("Kein Sicherheitscode mit der Session assoziiert. Der Code ");
          break;
        case grpc.Code.OutOfRange:
          setDisplayError("Sicherheitscode abgelaufen. Fordern Sie einen neuen Reset an.");
          setTimeout(authRecover, 5000);
          break;
        case grpc.Code.PermissionDenied:
          setDisplayError("Code ungültig. Bitte erneut probieren.");
          break;
        case grpc.Code.DeadlineExceeded:
        case grpc.Code.Canceled:
        case grpc.Code.Unavailable:
          setDisplayError(
            "Netzwerkproblem. Bitte erneut probieren. (Info: " + err.toString() + ")"
          );
          break;
        default:
          setDisplayError("Interner Fehler. Error: " + err.toString());
      }
    }
  }, [code, authRecover]);

  const [isChanging, setIsChanging] = useState(false);
  const [selectedIdx, setSelectedIdx] = useState<number | null>(null);

  useEffect(() => {
    async function fetchData() {
      try {
        if (emailAddresses.length === 1 || selectedIdx != null) {
          await idpc.SendAuthMail({
            type: SendAuthMailRequest_Type.RESET,
            mechanismToBeReset: Mechanism.PASSWORD,
            id: emailAddresses[selectedIdx ?? 0].id,
          });
        }
      } catch (err) {}
    }
    fetchData();
  }, [emailAddresses, selectedIdx]);

  if (isChanging)
    return (
      <ChangePassword
        authRecover={props.authRecover}
        oldPassword=""
        reason={AuthPasswordExtension.Reset}
      />
    );

  if (props.emailAddresses.length === 0) {
    return (
      <ErrorLayout
        icon={mdiAlert}
        primaryMessage="Passwort-Rücksetzung"
        secondaryMessage="Sie haben keine E-Mail-Adresse hinterlegt."
      />
    );
  } else if (props.emailAddresses.length === 1) {
    return (
      <>
        <EmailAddressItem spec={props.emailAddresses[0]} />
        <Prompt>
          Bitte geben Sie den Sicherheitscode ein, der an Ihre @{props.emailAddresses[0].domain}
          -Adresse gesendet wurde:
        </Prompt>
        <Input
          name="Sicherheitscode"
          type="text"
          value={code}
          inputMode="numeric"
          autoComplete="one-time-code"
          autoFocus
          onChange={setCode}
          onEnter={onCodeSubmit}
        />
        <ErrorMessage error={displayError} />
        <Button onClick={onCodeSubmit}>Weiter</Button>
      </>
    );
  } else {
    if (selectedIdx === null) {
      return (
        <>
          <Prompt>Bitte wählen Sie eine E-Mail-Adresse aus:</Prompt>
          {props.emailAddresses.map((e, i) => (
            <EmailAddressItem spec={e} onClick={() => setSelectedIdx(i)} />
          ))}
        </>
      );
    } else {
      return (
        <>
          <EmailAddressItem spec={props.emailAddresses[selectedIdx]} />
          <Prompt>
            Bitte geben Sie den Sicherheitscode ein, der an Ihre @
            {props.emailAddresses[selectedIdx].domain}-Adresse gesendet wurde:
          </Prompt>
          <Input
            name="Sicherheitscode"
            type="text"
            value={code}
            inputMode="numeric"
            autoComplete="one-time-code"
            autoFocus
            onChange={setCode}
            onEnter={onCodeSubmit}
          />
          <ErrorMessage error={displayError} />
          <Button onClick={onCodeSubmit}>Weiter</Button>
        </>
      );
    }
  }
}

const ResetLink = styled.a`
  color: #989898;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 15px;
  display: block;
`;

enum ExtendedPasswordResult {
  emptyPassword = 1,
}

export function Password(props: {
  authContinue: (c: AuthContinue) => void;
  authRecover: () => void;
  authCtx?: AuthContext;
  emailAddresses: EmailAddress[];
}): JSX.Element {
  const theme = useTheme();
  const [password, setPassword] = useState("");
  const [result, setResult] =
    useState<AuthPasswordResponse_Result | ExtendedPasswordResult | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [nextTry, setNextTry] = useState<Date | null>(null);
  const [isResetting, setIsResetting] = useState(false);
  const onNext = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      if (password.trim().length === 0) {
        setResult(ExtendedPasswordResult.emptyPassword);
        return;
      }
      try {
        setError(null);
        setResult(null);
        const res = await idpc.AuthPassword({
          password: password,
          authCtx: props.authCtx,
        });
        if (res.result === AuthPasswordResponse_Result.SUCCESS) {
          props.authContinue(res.authContinue!);
        }
        if (res.result === AuthPasswordResponse_Result.RATE_LIMITED) {
          setNextTry(new Date(new Date().getTime() + 5 * 60000));
          setTimeout(() => {
            setError(null);
            setResult(null);
          }, 5 * 60000);
        }
        setResult(res.result);
        if (
          res.result !== AuthPasswordResponse_Result.MUST_CHANGE_POLICY &&
          res.result !== AuthPasswordResponse_Result.MUST_CHANGE_EXPOSED
        )
          setPassword("");
      } catch (err) {
        if (err.code === grpc.Code.Unauthenticated || err.code === grpc.Code.FailedPrecondition) {
          props.authRecover();
        }
        setError(err.toString());
      }
    },
    [password, props]
  );

  const onReset = useCallback(() => {
    setIsResetting(true);
  }, []);

  if (isResetting)
    return <ResetPassword authRecover={props.authRecover} emailAddresses={props.emailAddresses} />;

  let displayError: string | null = null;
  if (error !== null) {
    displayError = "Bitte versuchen Sie es später nochmals. Details: " + error;
  } else {
    switch (result) {
      case AuthPasswordResponse_Result.WRONG_PASSWORD:
        displayError = "Falsches Passwort.";
        break;
      case AuthPasswordResponse_Result.LOCKED_OUT:
        return (
          <ErrorLayout
            icon={mdiKeyRemove}
            primaryMessage="Es wurden zu viele fehlgeschlagene Anmeldungen mit diesem Mechanismus gemacht. Dies deutet auf einen Angriff auf Ihren Account hin. Aus Sicherheitsgründen wurde der Mechanismus deaktiviert."
            secondaryMessage={theme.branding?.supportContactText}
          />
        );
      case AuthPasswordResponse_Result.RATE_LIMITED:
        return (
          <ErrorLayout
            icon={mdiTimerSand}
            primaryMessage="Sie haben zu viele falsche Passwörter in den letzen paar Minuten probiert. Bitte probieren Sie es später wieder."
            secondaryMessage="Nächster Versuch in 5m"
          />
        );
      case AuthPasswordResponse_Result.MUST_CHANGE_POLICY:
      case AuthPasswordResponse_Result.MUST_CHANGE_EXPOSED:
        return (
          <ChangePassword oldPassword={password} authRecover={props.authRecover} reason={result} />
        );
      case ExtendedPasswordResult.emptyPassword:
        displayError = "Bitte geben Sie ein Passwort ein.";
        break;
    }
  }

  return (
    <form onSubmit={onNext}>
      <Prompt>Bitte geben Sie Ihr Passwort ein:</Prompt>
      <Input
        name="Passwort"
        type="password"
        value={password}
        autoFocus
        autoComplete="current-password webauthn"
        onChange={setPassword}
      />
      <ErrorMessage error={displayError} />
      <ButtonBox>
        <Button type="submit">Weiter</Button>
      </ButtonBox>
      <ResetLink onClick={onReset}>Passwort vergessen?</ResetLink>
    </form>
  );
}
