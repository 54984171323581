import React from "react";
import styled, { useTheme } from "styled-components/macro";
import { Branding_LogoStyle } from "./generated/idp/api/idp";

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const ImgLogo = styled.img`
  max-height: 72px;
  height: 100%;
  padding: 8px;
  box-sizing: border-box;
`;

const Title = styled.h1`
  @media (max-width: 500px) {
    margin: 0px;
    font-size: 24px;
  }
  font-size: 32px;
`;

export function Brand(props: {}): JSX.Element {
  const theme = useTheme();
  if (theme.branding === undefined) {
    return <></>;
  }
  const branding = theme.branding;
  return (
    <Container>
      {branding.logoStyle === Branding_LogoStyle.WORDMARK ||
      branding.logoStyle === Branding_LogoStyle.BRAND_NAME_WITH_LOGO ? (
        <ImgLogo
          src={`/branding/${theme.branding?.logoFilename}`}
          alt={theme.branding?.brand}
        />
      ) : null}
      {branding.logoStyle === Branding_LogoStyle.BRAND_NAME_ONLY ||
      branding.logoStyle === Branding_LogoStyle.BRAND_NAME_WITH_LOGO ? (
        <Title>{theme.branding?.brand}</Title>
      ) : null}
    </Container>
  );
}
