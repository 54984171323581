import { mdiMenuDown } from "@mdi/js";
import Icon from "@mdi/react";
import Avatar from "boring-avatars";
import React from "react";
import styled from "styled-components/macro";
import { ClientProfile, UserProfile } from "../generated/idp/api/idp";
import { FakeUserImage } from "./FakeUserImage";

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

const Line = styled.div`
  border-top: 2px solid #aaa;
  flex-grow: 1;
`;

const Pill = styled.div`
  display: flex;
  align-items: center;
  border-radius: 20px;
  margin: 0 auto;
  border: 2px solid #aaa;
  height: 32px;
`;

const UserImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 16px;
`;

const RectangularIcon = styled.img`
  width: 26px;
  height: 26px;
  margin-left: 6px;
`;

const SwitchIcon = styled.button`
  outline: none;
  background: none;
  border: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
  padding: 0;
  color: #444;
  :hover {
    color: #666;
  }
`;

const Name = styled.span`
  display: inline-block;
  margin-left: 15px;
`;

export function UserProfilePill(props: {
  profile?: UserProfile;
  clientProfile?: ClientProfile;
  onSwitch?: () => void;
}): JSX.Element {
  return (
    <Container>
      <Line />
      {props.profile ? (
        <Pill>
          {props.profile.picture === "" ? (
            <Avatar size={32} name={props.profile.name} variant="marble" />
          ) : (
            <UserImage src={props.profile.picture} />
          )}
          <Name>{props.profile.name}</Name>
          {props.onSwitch ? (
            <SwitchIcon onClick={props.onSwitch}>
              <Icon path={mdiMenuDown} />
            </SwitchIcon>
          ) : null}
        </Pill>
      ) : null}
      {props.profile && props.clientProfile ? "➞" : null}
      {props.clientProfile ? (
        <Pill>
          {props.clientProfile.logoUrl === "" ? (
            <FakeUserImage name={props.clientProfile.displayName} size={32} />
          ) : (
            <RectangularIcon
              alt={props.clientProfile.displayName}
              src={props.clientProfile.logoUrl}
            />
          )}
          <Name>{props.clientProfile.displayName ?? " "}</Name>
          &nbsp; &nbsp;
        </Pill>
      ) : null}
      <Line />
    </Container>
  );
}
