import React, { useEffect } from "react";

export function useDebug(keySequence: string[], cb: () => void, sensitivity: React.DependencyList) {
  useEffect(() => {
    let lastInputs = new Array(keySequence.length);
    const handler = ({ key }: KeyboardEvent) => {
      lastInputs = [...lastInputs.slice(1), key];
      if (keySequence.every((v, k) => v === lastInputs[k])) cb();
    };
    window.addEventListener("keydown", handler);
    return () => window.removeEventListener("keydown", handler);
  }, sensitivity);
}
