import { grpc } from "@improbable-eng/grpc-web";
import { mdiPencil, mdiPlusCircle, mdiTrashCanOutline } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { Nav } from "../account/Nav";
import { getErrorDetail, oauth2a, saml2a } from "../api";
import { AuthContext, UserProfile } from "../generated/idp/api/idp";
import { Client } from "../generated/idp/api/oauth2/api";
import { ServiceProvider } from "../generated/idp/api/saml2/api";
import { FakeUserImage } from "../login/FakeUserImage";
import { nanoid } from "../nanoid";
import { goToLocation } from "../router";

const IconButton = styled.button`
  border: none;
  background: none;
  border-radius: 5px;
  color: #666;
  cursor: pointer;
  :hover {
    background: rgba(0, 0, 0, 0.15);
    color: black;
  }
  :active {
    background: rgba(0, 0, 0, 0.3);
  }
`;

const StripedTBody = styled.tbody`
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

function ConfirmingDeleteButton({ onDelete }: { onDelete: () => void }): JSX.Element {
  const [isConfirming, setIsConfirming] = useState(false);
  const cb = useCallback(() => {
    if (!isConfirming) {
      setIsConfirming(true);
    } else {
      onDelete();
    }
  }, [isConfirming, onDelete]);
  const ref = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      // https://github.com/DefinitelyTyped/DefinitelyTyped/pull/12239
      if (ref.current && !ref.current.contains(e.target as any)) {
        setIsConfirming(false);
      }
    };
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [ref]);
  return (
    <IconButton
      ref={ref}
      onClick={cb}
      style={isConfirming ? { color: "white", backgroundColor: "red" } : {}}
    >
      <Icon size={1} path={mdiTrashCanOutline} />
    </IconButton>
  );
}

const compare = new Intl.Collator().compare;

export function Apps(props: {
  profile: UserProfile | null;
  onLogout: () => void;
  authenticate: (a: AuthContext) => void;
}): JSX.Element {
  const [clients, setClients] = useState<Client[] | null>(null);
  const authenticate = props.authenticate;

  const [sps, setSPs] = useState<ServiceProvider[] | null>(null);

  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      try {
        const res = await oauth2a.ListClients({});
        if (!ignore) {
          res.client.sort((a, b) =>
            compare(a.profile?.displayName ?? a.id, b.profile?.displayName ?? b.id)
          );
          setClients(res.client ?? null);
        }
      } catch (err) {
        if (err.code === grpc.Code.Unauthenticated) {
          let ia = getErrorDetail(err, AuthContext);
          if (ia !== null) {
            authenticate(ia);
          }
        }
      }
    };
    fetchData();
    return () => {
      ignore = true;
    };
  }, [authenticate]);

  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      try {
        const res = await saml2a.ListServiceProviders({});
        if (!ignore) {
          res.serviceProvider.sort((a, b) =>
            compare(a.profile?.displayName ?? a.id, b.profile?.displayName ?? b.id)
          );
          setSPs(res.serviceProvider ?? null);
        }
      } catch (err) {
        if (err.code === grpc.Code.Unauthenticated) {
          let ia = getErrorDetail(err, AuthContext);
          if (ia !== null) {
            authenticate(ia);
          }
        }
      }
    };
    fetchData();
    return () => {
      ignore = true;
    };
  }, [authenticate]);

  return (
    <Nav profile={props.profile ?? undefined} onLogout={props.onLogout}>
      <h2>
        OAuth 2.0 / OIDC Apps&nbsp;
        <IconButton
          onClick={() => {
            goToLocation({ pathname: "/admin/apps/oidc/" + nanoid(), search: "?new" });
          }}
        >
          <Icon size={1} path={mdiPlusCircle} />
        </IconButton>
      </h2>
      <table style={{ width: "100%" }}>
        <thead style={{ textAlign: "left" }}>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Client ID</th>
            <th>Allowed Roles</th>
            <th>Actions</th>
          </tr>
        </thead>
        <StripedTBody>
          {(clients ?? []).map((c, i) => (
            <tr key={c.id}>
              <td>
                {c.profile?.logoUrl !== "" ? (
                  <img alt={c.profile?.displayName} src={c.profile?.logoUrl} height="24" />
                ) : (
                  <FakeUserImage name={c.profile.displayName} size={24} />
                )}
              </td>
              <td>{c.profile?.displayName}</td>
              <td>{c.id}</td>
              <td>{c.restrictToGroup.length === 0 ? <i>All</i> : c.restrictToGroup.join(", ")}</td>
              <td>
                <IconButton onClick={() => goToLocation("/admin/apps/oidc/" + c.id)}>
                  <Icon size={1} path={mdiPencil} />
                </IconButton>
                <ConfirmingDeleteButton
                  onDelete={async () => {
                    try {
                      await oauth2a.ReplaceClient({ oldClientId: c.id });
                      if (clients) {
                        setClients([...clients.slice(0, i), ...clients.slice(i + 1)]);
                      }
                    } catch (err) {
                      alert(err);
                    }
                  }}
                />
              </td>
            </tr>
          ))}
        </StripedTBody>
      </table>
      <h2>SAML / WS-Fed Apps</h2>
      <table style={{ width: "100%" }}>
        <thead style={{ textAlign: "left" }}>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Entity ID</th>
            <th>Allowed Roles</th>
            <th>Actions</th>
          </tr>
        </thead>
        <StripedTBody>
          {(sps ?? []).map((c) => (
            <tr key={c.id}>
              <td>
                {c.profile?.logoUrl !== "" ? (
                  <img alt={c.profile?.displayName} src={c.profile?.logoUrl} height="24" />
                ) : (
                  <FakeUserImage name={c.profile.displayName} size={24} />
                )}
              </td>
              <td>{c.profile?.displayName}</td>
              <td>{c.id}</td>
              <td>{c.restrictToGroup.length === 0 ? <i>All</i> : c.restrictToGroup.join(", ")}</td>
              <td></td>
            </tr>
          ))}
        </StripedTBody>
      </table>
    </Nav>
  );
}
