import Icon from "@mdi/react";
import { lighten } from "polished";
import React from "react";
import styled from "styled-components/macro";

const Container = styled.div`
  background: #d6d6d6;
  text-align: left;
  display: flex;
  align-items: center;
  border-radius: 25px;
  padding-left: 20px;
  padding-right: 20px;
  height: 50px;
  margin-bottom: 20px;
`;

const ContainerButton = styled.button`
  background: #d6d6d6;
  text-align: left;
  display: flex;
  align-items: center;
  border-radius: 25px;
  padding-left: 20px;
  padding-right: 20px;
  height: 50px;
  margin-bottom: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
  :hover {
    background: ${lighten(0.05, "#d6d6d6")};
  }
`;

const DeviceIcon = styled(Icon)`
  margin-right: 10px;
`;

export function DeviceItem(props: {
  icon: string;
  name: string;
  onClick?: () => void;
}): JSX.Element {
  const content = (
    <>
      <DeviceIcon path={props.icon} size={1.5} /> {props.name}
    </>
  );
  return props.onClick ? (
    <ContainerButton onClick={props.onClick}>{content}</ContainerButton>
  ) : (
    <Container>{content}</Container>
  );
}
