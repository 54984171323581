import React from "react";
import { Nav } from "./Nav";
import { ReactComponent as Construction } from "./construction.svg";

export function Sessions() {
  return (
    <Nav>
      <Construction />
    </Nav>
  );
}
