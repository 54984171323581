import "normalize.css";
import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import { NavigatorUAData } from "./uatypes";
import { StyleSheetManager } from "styled-components/macro";
import Modal from "react-modal";

declare global {
  interface Navigator {
    userAgentData: NavigatorUAData;
  }
}

const rootElement = document.getElementById("root");

// Workaround for webviews which do not have a proper native size.
if (rootElement && rootElement.clientHeight === 0) {
  rootElement.style.height = window.innerHeight + "px";
  rootElement.style.minHeight = window.innerHeight + "px";
}

if (rootElement) {
  const root = createRoot(rootElement);

  Modal.setAppElement(rootElement);

  root.render(
    <React.StrictMode>
      <StyleSheetManager enableVendorPrefixes>
        <App />
      </StyleSheetManager>
    </React.StrictMode>
  );
}