/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { Empty } from "../../../google/protobuf/empty";
import { messageTypeRegistry } from "../../../typeRegistry";
import { PublicKey, SignatureAlgorithm } from "../cryptopb/cryptopb";
import { ClientProfile } from "../idp";

export const protobufPackage = "idp.saml2";

export enum Binding {
  HTTP_POST = 0,
  HTTP_POST_SIMPLE_SIGN = 1,
  HTTP_REDIRECT = 2,
  UNRECOGNIZED = -1,
}

export enum NameIDFormat {
  UNSPECIFIED = 0,
  TRANSIENT = 1,
  PERSISTENT = 2,
  EMAIL_ADDRESS = 3,
  SHIBBOLETH = 4,
  UNRECOGNIZED = -1,
}

export interface AssertionConsumerService {
  $type: "idp.saml2.AssertionConsumerService";
  url: string;
  binding: Binding;
  index: number;
}

export interface SingleLogoutService {
  $type: "idp.saml2.SingleLogoutService";
  url: string;
  binding: Binding;
}

export interface AttributeSpec {
  $type: "idp.saml2.AttributeSpec";
  /**
   * If set to true, a multi-valued attribute named "groups" with all groups the
   * user is a member of is added.
   */
  enableGroups: boolean;
  enableName: boolean;
  enableEmail: boolean;
  /**
   * A JavaScript script which gets the User in protojson form as the "user"
   * global and can add attributes to the "attributes" global.
   */
  mappingScript: string;
}

/** SAML2 ServiceProvider config */
export interface ServiceProvider {
  $type: "idp.saml2.ServiceProvider";
  /** SAML2 Entity ID */
  id: string;
  /**
   * List of assertion consumer services and associated bindings and indices.
   * The first acceptable service is used, so list them in order of preference.
   */
  assertionConsumerService: AssertionConsumerService[];
  /** Acceptable NameIDFormats in order of preference. */
  nameIdFormat: NameIDFormat[];
  /**
   * Supported signature algorithms in order of preference. Note that only the
   * RS* family of RSA-based signature algorithms is supported currently.
   */
  signatureAlgorithm: SignatureAlgorithm[];
  /** Service to which Logout requests and responses should be directed to. */
  singleLogoutService:
    | SingleLogoutService
    | undefined;
  /** Keys used to sign requests */
  signingKey: PublicKey[];
  /**
   * URL to redirect the user to to get a new request. This is used to resolve
   * situations where we don't have a valid request from the SP anymore like
   * when it expired or the user used the back button to get to the IDP.
   */
  newLoginUrl: string;
  /** Controls attributes passed to this SP */
  attributes:
    | AttributeSpec
    | undefined;
  /** Only issue when user is a member of any of these groups */
  restrictToGroup: string[];
  /** Only issue when authentication fulfills any of these classes */
  restrictToAcr: string[];
  profile: ClientProfile | undefined;
}

/**
 * RedirectMessage contains a SAML message represended for the redirect binding.
 * These are the raw, url-encoded values as they are needed for verifying the
 * signature. See 3.4.4.1 of the SAML 2.0 Bindings standard.
 */
export interface RedirectMessage {
  $type: "idp.saml2.RedirectMessage";
  request: string;
  relayState: string;
  signatureAlgorithm: string;
  /** URL and Base64-decoded */
  signature: Uint8Array;
}

/**
 * POSTMessage contains a SAML message represented for the POST binding (See
 * Section 3.5).
 */
export interface POSTMessage {
  $type: "idp.saml2.POSTMessage";
  /** request contains the raw XML obtained by decoding the base64 payload. */
  request: Uint8Array;
  relayState: string;
}

/** Message contains a SAML message in any of the supported bindings. */
export interface Message {
  $type: "idp.saml2.Message";
  binding?: { $case: "post"; post: POSTMessage } | { $case: "redirect"; redirect: RedirectMessage } | undefined;
}

export interface SSORequest {
  $type: "idp.saml2.SSORequest";
  request: Message | undefined;
}

export interface SSOResponse {
  $type: "idp.saml2.SSOResponse";
  response: Message | undefined;
  url: string;
}

export interface LogoutRequest {
  $type: "idp.saml2.LogoutRequest";
  request: Message | undefined;
}

export interface LogoutResponse {
  $type: "idp.saml2.LogoutResponse";
  response: Message | undefined;
  url: string;
}

export interface Consent {
  $type: "idp.saml2.Consent";
  serviceProviderId: string;
  subject: string;
}

export interface ListServiceProvidersRequest {
  $type: "idp.saml2.ListServiceProvidersRequest";
}

export interface ListServiceProvidersResponse {
  $type: "idp.saml2.ListServiceProvidersResponse";
  serviceProvider: ServiceProvider[];
}

export interface CreateServiceProviderRequest {
  $type: "idp.saml2.CreateServiceProviderRequest";
  serviceProvider: ServiceProvider | undefined;
  failIfAlreadyExists: boolean;
}

export interface DeleteServiceProviderRequest {
  $type: "idp.saml2.DeleteServiceProviderRequest";
  id: string;
}

function createBaseAssertionConsumerService(): AssertionConsumerService {
  return { $type: "idp.saml2.AssertionConsumerService", url: "", binding: 0, index: 0 };
}

export const AssertionConsumerService = {
  $type: "idp.saml2.AssertionConsumerService" as const,

  encode(message: AssertionConsumerService, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    if (message.binding !== 0) {
      writer.uint32(16).int32(message.binding);
    }
    if (message.index !== 0) {
      writer.uint32(24).int32(message.index);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertionConsumerService {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertionConsumerService();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.binding = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.index = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AssertionConsumerService>, I>>(base?: I): AssertionConsumerService {
    return AssertionConsumerService.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertionConsumerService>, I>>(object: I): AssertionConsumerService {
    const message = createBaseAssertionConsumerService();
    message.url = object.url ?? "";
    message.binding = object.binding ?? 0;
    message.index = object.index ?? 0;
    return message;
  },
};

messageTypeRegistry.set(AssertionConsumerService.$type, AssertionConsumerService);

function createBaseSingleLogoutService(): SingleLogoutService {
  return { $type: "idp.saml2.SingleLogoutService", url: "", binding: 0 };
}

export const SingleLogoutService = {
  $type: "idp.saml2.SingleLogoutService" as const,

  encode(message: SingleLogoutService, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    if (message.binding !== 0) {
      writer.uint32(16).int32(message.binding);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SingleLogoutService {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSingleLogoutService();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.binding = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SingleLogoutService>, I>>(base?: I): SingleLogoutService {
    return SingleLogoutService.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SingleLogoutService>, I>>(object: I): SingleLogoutService {
    const message = createBaseSingleLogoutService();
    message.url = object.url ?? "";
    message.binding = object.binding ?? 0;
    return message;
  },
};

messageTypeRegistry.set(SingleLogoutService.$type, SingleLogoutService);

function createBaseAttributeSpec(): AttributeSpec {
  return {
    $type: "idp.saml2.AttributeSpec",
    enableGroups: false,
    enableName: false,
    enableEmail: false,
    mappingScript: "",
  };
}

export const AttributeSpec = {
  $type: "idp.saml2.AttributeSpec" as const,

  encode(message: AttributeSpec, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enableGroups === true) {
      writer.uint32(8).bool(message.enableGroups);
    }
    if (message.enableName === true) {
      writer.uint32(16).bool(message.enableName);
    }
    if (message.enableEmail === true) {
      writer.uint32(24).bool(message.enableEmail);
    }
    if (message.mappingScript !== "") {
      writer.uint32(42).string(message.mappingScript);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AttributeSpec {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttributeSpec();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.enableGroups = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enableName = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.enableEmail = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.mappingScript = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AttributeSpec>, I>>(base?: I): AttributeSpec {
    return AttributeSpec.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AttributeSpec>, I>>(object: I): AttributeSpec {
    const message = createBaseAttributeSpec();
    message.enableGroups = object.enableGroups ?? false;
    message.enableName = object.enableName ?? false;
    message.enableEmail = object.enableEmail ?? false;
    message.mappingScript = object.mappingScript ?? "";
    return message;
  },
};

messageTypeRegistry.set(AttributeSpec.$type, AttributeSpec);

function createBaseServiceProvider(): ServiceProvider {
  return {
    $type: "idp.saml2.ServiceProvider",
    id: "",
    assertionConsumerService: [],
    nameIdFormat: [],
    signatureAlgorithm: [],
    singleLogoutService: undefined,
    signingKey: [],
    newLoginUrl: "",
    attributes: undefined,
    restrictToGroup: [],
    restrictToAcr: [],
    profile: undefined,
  };
}

export const ServiceProvider = {
  $type: "idp.saml2.ServiceProvider" as const,

  encode(message: ServiceProvider, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    for (const v of message.assertionConsumerService) {
      AssertionConsumerService.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(26).fork();
    for (const v of message.nameIdFormat) {
      writer.int32(v);
    }
    writer.ldelim();
    writer.uint32(42).fork();
    for (const v of message.signatureAlgorithm) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.singleLogoutService !== undefined) {
      SingleLogoutService.encode(message.singleLogoutService, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.signingKey) {
      PublicKey.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.newLoginUrl !== "") {
      writer.uint32(82).string(message.newLoginUrl);
    }
    if (message.attributes !== undefined) {
      AttributeSpec.encode(message.attributes, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.restrictToGroup) {
      writer.uint32(58).string(v!);
    }
    for (const v of message.restrictToAcr) {
      writer.uint32(74).string(v!);
    }
    if (message.profile !== undefined) {
      ClientProfile.encode(message.profile, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServiceProvider {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServiceProvider();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.assertionConsumerService.push(AssertionConsumerService.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag === 24) {
            message.nameIdFormat.push(reader.int32() as any);

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.nameIdFormat.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 5:
          if (tag === 40) {
            message.signatureAlgorithm.push(reader.int32() as any);

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.signatureAlgorithm.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.singleLogoutService = SingleLogoutService.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.signingKey.push(PublicKey.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.newLoginUrl = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.attributes = AttributeSpec.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.restrictToGroup.push(reader.string());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.restrictToAcr.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.profile = ClientProfile.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ServiceProvider>, I>>(base?: I): ServiceProvider {
    return ServiceProvider.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServiceProvider>, I>>(object: I): ServiceProvider {
    const message = createBaseServiceProvider();
    message.id = object.id ?? "";
    message.assertionConsumerService =
      object.assertionConsumerService?.map((e) => AssertionConsumerService.fromPartial(e)) || [];
    message.nameIdFormat = object.nameIdFormat?.map((e) => e) || [];
    message.signatureAlgorithm = object.signatureAlgorithm?.map((e) => e) || [];
    message.singleLogoutService = (object.singleLogoutService !== undefined && object.singleLogoutService !== null)
      ? SingleLogoutService.fromPartial(object.singleLogoutService)
      : undefined;
    message.signingKey = object.signingKey?.map((e) => PublicKey.fromPartial(e)) || [];
    message.newLoginUrl = object.newLoginUrl ?? "";
    message.attributes = (object.attributes !== undefined && object.attributes !== null)
      ? AttributeSpec.fromPartial(object.attributes)
      : undefined;
    message.restrictToGroup = object.restrictToGroup?.map((e) => e) || [];
    message.restrictToAcr = object.restrictToAcr?.map((e) => e) || [];
    message.profile = (object.profile !== undefined && object.profile !== null)
      ? ClientProfile.fromPartial(object.profile)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(ServiceProvider.$type, ServiceProvider);

function createBaseRedirectMessage(): RedirectMessage {
  return {
    $type: "idp.saml2.RedirectMessage",
    request: "",
    relayState: "",
    signatureAlgorithm: "",
    signature: new Uint8Array(0),
  };
}

export const RedirectMessage = {
  $type: "idp.saml2.RedirectMessage" as const,

  encode(message: RedirectMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.request !== "") {
      writer.uint32(10).string(message.request);
    }
    if (message.relayState !== "") {
      writer.uint32(18).string(message.relayState);
    }
    if (message.signatureAlgorithm !== "") {
      writer.uint32(26).string(message.signatureAlgorithm);
    }
    if (message.signature.length !== 0) {
      writer.uint32(34).bytes(message.signature);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RedirectMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRedirectMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.request = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.relayState = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.signatureAlgorithm = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.signature = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RedirectMessage>, I>>(base?: I): RedirectMessage {
    return RedirectMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RedirectMessage>, I>>(object: I): RedirectMessage {
    const message = createBaseRedirectMessage();
    message.request = object.request ?? "";
    message.relayState = object.relayState ?? "";
    message.signatureAlgorithm = object.signatureAlgorithm ?? "";
    message.signature = object.signature ?? new Uint8Array(0);
    return message;
  },
};

messageTypeRegistry.set(RedirectMessage.$type, RedirectMessage);

function createBasePOSTMessage(): POSTMessage {
  return { $type: "idp.saml2.POSTMessage", request: new Uint8Array(0), relayState: "" };
}

export const POSTMessage = {
  $type: "idp.saml2.POSTMessage" as const,

  encode(message: POSTMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.request.length !== 0) {
      writer.uint32(10).bytes(message.request);
    }
    if (message.relayState !== "") {
      writer.uint32(18).string(message.relayState);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): POSTMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePOSTMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.request = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.relayState = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<POSTMessage>, I>>(base?: I): POSTMessage {
    return POSTMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<POSTMessage>, I>>(object: I): POSTMessage {
    const message = createBasePOSTMessage();
    message.request = object.request ?? new Uint8Array(0);
    message.relayState = object.relayState ?? "";
    return message;
  },
};

messageTypeRegistry.set(POSTMessage.$type, POSTMessage);

function createBaseMessage(): Message {
  return { $type: "idp.saml2.Message", binding: undefined };
}

export const Message = {
  $type: "idp.saml2.Message" as const,

  encode(message: Message, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.binding?.$case) {
      case "post":
        POSTMessage.encode(message.binding.post, writer.uint32(10).fork()).ldelim();
        break;
      case "redirect":
        RedirectMessage.encode(message.binding.redirect, writer.uint32(18).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Message {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.binding = { $case: "post", post: POSTMessage.decode(reader, reader.uint32()) };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.binding = { $case: "redirect", redirect: RedirectMessage.decode(reader, reader.uint32()) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Message>, I>>(base?: I): Message {
    return Message.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Message>, I>>(object: I): Message {
    const message = createBaseMessage();
    if (object.binding?.$case === "post" && object.binding?.post !== undefined && object.binding?.post !== null) {
      message.binding = { $case: "post", post: POSTMessage.fromPartial(object.binding.post) };
    }
    if (
      object.binding?.$case === "redirect" &&
      object.binding?.redirect !== undefined &&
      object.binding?.redirect !== null
    ) {
      message.binding = { $case: "redirect", redirect: RedirectMessage.fromPartial(object.binding.redirect) };
    }
    return message;
  },
};

messageTypeRegistry.set(Message.$type, Message);

function createBaseSSORequest(): SSORequest {
  return { $type: "idp.saml2.SSORequest", request: undefined };
}

export const SSORequest = {
  $type: "idp.saml2.SSORequest" as const,

  encode(message: SSORequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.request !== undefined) {
      Message.encode(message.request, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SSORequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSSORequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.request = Message.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SSORequest>, I>>(base?: I): SSORequest {
    return SSORequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SSORequest>, I>>(object: I): SSORequest {
    const message = createBaseSSORequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Message.fromPartial(object.request)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(SSORequest.$type, SSORequest);

function createBaseSSOResponse(): SSOResponse {
  return { $type: "idp.saml2.SSOResponse", response: undefined, url: "" };
}

export const SSOResponse = {
  $type: "idp.saml2.SSOResponse" as const,

  encode(message: SSOResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.response !== undefined) {
      Message.encode(message.response, writer.uint32(26).fork()).ldelim();
    }
    if (message.url !== "") {
      writer.uint32(34).string(message.url);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SSOResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSSOResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.response = Message.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SSOResponse>, I>>(base?: I): SSOResponse {
    return SSOResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SSOResponse>, I>>(object: I): SSOResponse {
    const message = createBaseSSOResponse();
    message.response = (object.response !== undefined && object.response !== null)
      ? Message.fromPartial(object.response)
      : undefined;
    message.url = object.url ?? "";
    return message;
  },
};

messageTypeRegistry.set(SSOResponse.$type, SSOResponse);

function createBaseLogoutRequest(): LogoutRequest {
  return { $type: "idp.saml2.LogoutRequest", request: undefined };
}

export const LogoutRequest = {
  $type: "idp.saml2.LogoutRequest" as const,

  encode(message: LogoutRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.request !== undefined) {
      Message.encode(message.request, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogoutRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogoutRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.request = Message.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<LogoutRequest>, I>>(base?: I): LogoutRequest {
    return LogoutRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LogoutRequest>, I>>(object: I): LogoutRequest {
    const message = createBaseLogoutRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Message.fromPartial(object.request)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(LogoutRequest.$type, LogoutRequest);

function createBaseLogoutResponse(): LogoutResponse {
  return { $type: "idp.saml2.LogoutResponse", response: undefined, url: "" };
}

export const LogoutResponse = {
  $type: "idp.saml2.LogoutResponse" as const,

  encode(message: LogoutResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.response !== undefined) {
      Message.encode(message.response, writer.uint32(10).fork()).ldelim();
    }
    if (message.url !== "") {
      writer.uint32(18).string(message.url);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogoutResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogoutResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.response = Message.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<LogoutResponse>, I>>(base?: I): LogoutResponse {
    return LogoutResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LogoutResponse>, I>>(object: I): LogoutResponse {
    const message = createBaseLogoutResponse();
    message.response = (object.response !== undefined && object.response !== null)
      ? Message.fromPartial(object.response)
      : undefined;
    message.url = object.url ?? "";
    return message;
  },
};

messageTypeRegistry.set(LogoutResponse.$type, LogoutResponse);

function createBaseConsent(): Consent {
  return { $type: "idp.saml2.Consent", serviceProviderId: "", subject: "" };
}

export const Consent = {
  $type: "idp.saml2.Consent" as const,

  encode(message: Consent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serviceProviderId !== "") {
      writer.uint32(10).string(message.serviceProviderId);
    }
    if (message.subject !== "") {
      writer.uint32(18).string(message.subject);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serviceProviderId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.subject = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Consent>, I>>(base?: I): Consent {
    return Consent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Consent>, I>>(object: I): Consent {
    const message = createBaseConsent();
    message.serviceProviderId = object.serviceProviderId ?? "";
    message.subject = object.subject ?? "";
    return message;
  },
};

messageTypeRegistry.set(Consent.$type, Consent);

function createBaseListServiceProvidersRequest(): ListServiceProvidersRequest {
  return { $type: "idp.saml2.ListServiceProvidersRequest" };
}

export const ListServiceProvidersRequest = {
  $type: "idp.saml2.ListServiceProvidersRequest" as const,

  encode(_: ListServiceProvidersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListServiceProvidersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListServiceProvidersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ListServiceProvidersRequest>, I>>(base?: I): ListServiceProvidersRequest {
    return ListServiceProvidersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListServiceProvidersRequest>, I>>(_: I): ListServiceProvidersRequest {
    const message = createBaseListServiceProvidersRequest();
    return message;
  },
};

messageTypeRegistry.set(ListServiceProvidersRequest.$type, ListServiceProvidersRequest);

function createBaseListServiceProvidersResponse(): ListServiceProvidersResponse {
  return { $type: "idp.saml2.ListServiceProvidersResponse", serviceProvider: [] };
}

export const ListServiceProvidersResponse = {
  $type: "idp.saml2.ListServiceProvidersResponse" as const,

  encode(message: ListServiceProvidersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.serviceProvider) {
      ServiceProvider.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListServiceProvidersResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListServiceProvidersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serviceProvider.push(ServiceProvider.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ListServiceProvidersResponse>, I>>(base?: I): ListServiceProvidersResponse {
    return ListServiceProvidersResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListServiceProvidersResponse>, I>>(object: I): ListServiceProvidersResponse {
    const message = createBaseListServiceProvidersResponse();
    message.serviceProvider = object.serviceProvider?.map((e) => ServiceProvider.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(ListServiceProvidersResponse.$type, ListServiceProvidersResponse);

function createBaseCreateServiceProviderRequest(): CreateServiceProviderRequest {
  return { $type: "idp.saml2.CreateServiceProviderRequest", serviceProvider: undefined, failIfAlreadyExists: false };
}

export const CreateServiceProviderRequest = {
  $type: "idp.saml2.CreateServiceProviderRequest" as const,

  encode(message: CreateServiceProviderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serviceProvider !== undefined) {
      ServiceProvider.encode(message.serviceProvider, writer.uint32(10).fork()).ldelim();
    }
    if (message.failIfAlreadyExists === true) {
      writer.uint32(16).bool(message.failIfAlreadyExists);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateServiceProviderRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateServiceProviderRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serviceProvider = ServiceProvider.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.failIfAlreadyExists = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateServiceProviderRequest>, I>>(base?: I): CreateServiceProviderRequest {
    return CreateServiceProviderRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateServiceProviderRequest>, I>>(object: I): CreateServiceProviderRequest {
    const message = createBaseCreateServiceProviderRequest();
    message.serviceProvider = (object.serviceProvider !== undefined && object.serviceProvider !== null)
      ? ServiceProvider.fromPartial(object.serviceProvider)
      : undefined;
    message.failIfAlreadyExists = object.failIfAlreadyExists ?? false;
    return message;
  },
};

messageTypeRegistry.set(CreateServiceProviderRequest.$type, CreateServiceProviderRequest);

function createBaseDeleteServiceProviderRequest(): DeleteServiceProviderRequest {
  return { $type: "idp.saml2.DeleteServiceProviderRequest", id: "" };
}

export const DeleteServiceProviderRequest = {
  $type: "idp.saml2.DeleteServiceProviderRequest" as const,

  encode(message: DeleteServiceProviderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteServiceProviderRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteServiceProviderRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<DeleteServiceProviderRequest>, I>>(base?: I): DeleteServiceProviderRequest {
    return DeleteServiceProviderRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteServiceProviderRequest>, I>>(object: I): DeleteServiceProviderRequest {
    const message = createBaseDeleteServiceProviderRequest();
    message.id = object.id ?? "";
    return message;
  },
};

messageTypeRegistry.set(DeleteServiceProviderRequest.$type, DeleteServiceProviderRequest);

export interface SAML2 {
  SSO(request: DeepPartial<SSORequest>, metadata?: grpc.Metadata): Promise<SSOResponse>;
  Logout(request: DeepPartial<LogoutRequest>, metadata?: grpc.Metadata): Promise<LogoutResponse>;
}

export class SAML2ClientImpl implements SAML2 {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.SSO = this.SSO.bind(this);
    this.Logout = this.Logout.bind(this);
  }

  SSO(request: DeepPartial<SSORequest>, metadata?: grpc.Metadata): Promise<SSOResponse> {
    return this.rpc.unary(SAML2SSODesc, SSORequest.fromPartial(request), metadata);
  }

  Logout(request: DeepPartial<LogoutRequest>, metadata?: grpc.Metadata): Promise<LogoutResponse> {
    return this.rpc.unary(SAML2LogoutDesc, LogoutRequest.fromPartial(request), metadata);
  }
}

export const SAML2Desc = { serviceName: "idp.saml2.SAML2" };

export const SAML2SSODesc: UnaryMethodDefinitionish = {
  methodName: "SSO",
  service: SAML2Desc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SSORequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SSOResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SAML2LogoutDesc: UnaryMethodDefinitionish = {
  methodName: "Logout",
  service: SAML2Desc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return LogoutRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = LogoutResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export interface SAML2Admin {
  ListServiceProviders(
    request: DeepPartial<ListServiceProvidersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListServiceProvidersResponse>;
  CreateServiceProvider(
    request: DeepPartial<CreateServiceProviderRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ServiceProvider>;
  DeleteServiceProvider(request: DeepPartial<DeleteServiceProviderRequest>, metadata?: grpc.Metadata): Promise<Empty>;
}

export class SAML2AdminClientImpl implements SAML2Admin {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.ListServiceProviders = this.ListServiceProviders.bind(this);
    this.CreateServiceProvider = this.CreateServiceProvider.bind(this);
    this.DeleteServiceProvider = this.DeleteServiceProvider.bind(this);
  }

  ListServiceProviders(
    request: DeepPartial<ListServiceProvidersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListServiceProvidersResponse> {
    return this.rpc.unary(
      SAML2AdminListServiceProvidersDesc,
      ListServiceProvidersRequest.fromPartial(request),
      metadata,
    );
  }

  CreateServiceProvider(
    request: DeepPartial<CreateServiceProviderRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ServiceProvider> {
    return this.rpc.unary(
      SAML2AdminCreateServiceProviderDesc,
      CreateServiceProviderRequest.fromPartial(request),
      metadata,
    );
  }

  DeleteServiceProvider(request: DeepPartial<DeleteServiceProviderRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(
      SAML2AdminDeleteServiceProviderDesc,
      DeleteServiceProviderRequest.fromPartial(request),
      metadata,
    );
  }
}

export const SAML2AdminDesc = { serviceName: "idp.saml2.SAML2Admin" };

export const SAML2AdminListServiceProvidersDesc: UnaryMethodDefinitionish = {
  methodName: "ListServiceProviders",
  service: SAML2AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListServiceProvidersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListServiceProvidersResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SAML2AdminCreateServiceProviderDesc: UnaryMethodDefinitionish = {
  methodName: "CreateServiceProvider",
  service: SAML2AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateServiceProviderRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ServiceProvider.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SAML2AdminDeleteServiceProviderDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteServiceProvider",
  service: SAML2AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteServiceProviderRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P> | "$type">]: never };

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
