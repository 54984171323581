import styled from "styled-components/macro";

export const Background = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.branding?.background ?? "black"};
`;

export const Card = styled.div<{ width: number }>`
  width: ${(p) => p.width}px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 50px;
  box-sizing: border-box;
`;

export const Prompt = styled.div`
  color: #505050;
  margin-bottom: 20px;
`;

export const SecondaryArea = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
`;
