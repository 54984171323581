import { grpc } from "@improbable-eng/grpc-web";
import React, { useEffect, useState } from "react";
import { Nav } from "../account/Nav";
import { getErrorDetail, idpac } from "../api";
import { AuthContext, User, User_Status } from "../generated/idp/api/idp";

export function Users(props: {
  onLogout: () => void;
  authenticate: (a: AuthContext) => void;
}): JSX.Element {
  const [users, setUsers] = useState<User[] | null>(null);
  const authenticate = props.authenticate;

  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      try {
        const res = await idpac.ListUsers({});
        if (!ignore) {
          setUsers(res.user ?? null);
        }
      } catch (err) {
        if (err.code === grpc.Code.Unauthenticated) {
          let ia = getErrorDetail(err, AuthContext);
          if (ia !== null) {
            authenticate(ia);
          }
        }
      }
    };
    fetchData();
    return () => {
      ignore = true;
    };
  }, [authenticate]);

  return (
    <Nav>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Aktiv</th>
          </tr>
        </thead>
        <tbody>
          {(users ?? []).map((u) => (
            <tr key={u.id}>
              <td>{u.id}</td>
              <td>{u.profile?.name}</td>
              <td>{u.status === User_Status.OK ? "☑" : "☒"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Nav>
  );
}
