import { mdiCheckboxMarkedCircle, mdiEmailOutline, mdiTimerSand } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";
import { idpc } from "../api";
import { Brand } from "../Brand";
import { Button, Danger, Secondary } from "../Button";
import { ErrorMessage } from "../ErrorMessage";
import {
  AuthBackchannelResponse_Result,
  AuthContext,
  AuthContinue,
  EmailAddress,
  EmailEnrollment,
  WatchEnrolmentEvent,
  WatchEnrolmentRequest_Type,
} from "../generated/idp/api/idp";
import { Input } from "../Input";
import { Spinner } from "../Spinner";
import { Background, Card } from "../UI";
import { DeviceItem } from "./DeviceItem";
import { ErrorLayout } from "./ErrorLayout";
import { Subscription } from "rxjs";

const Container = styled.div``;

const Prompt = styled.div`
  color: #505050;
`;

function useDescription({ domain, localPart }: EmailAddress) {
  return useMemo(() => {
    if (domain === "") {
      return "Ihre E-Mail-Adresse";
    } else if (localPart === "") {
      return `Ihre ${domain}-Adresse`;
    } else {
      return `${localPart}@${domain}`;
    }
  }, [domain, localPart]);
}

export function EmailAddressItem(props: { spec: EmailAddress; onClick?: () => void }): JSX.Element {
  const emailDesc = useDescription(props.spec);
  return (
    <DeviceItem icon={mdiEmailOutline} name={`Code an ${emailDesc}`} onClick={props.onClick} />
  );
}

export function EmailAddressEnrollItem(props: { onClick?: () => void }): JSX.Element {
  return (
    <DeviceItem icon={mdiEmailOutline} name="Neue E-Mail registrieren" onClick={props.onClick} />
  );
}

export function VerifyEmailAddressCallback(props: {}): JSX.Element {
  const [state, setState] = useState<"init" | "requesting" | "successful" | "failed">("init");
  const [error, setError] = useState<string | null>(null);
  const onClick = useCallback(async () => {
    setState("requesting");
    try {
      await idpc.VerifyMail({
        token: window.location.hash.substring(1),
      });
      setState("successful");
    } catch (err) {
      setError("" + err);
      setState("failed");
    }
  }, []);
  return (
    <Background>
      <Card width={500}>
        <Brand />
        {state === "init" ? (
          <>
            <p>Drücken Sie auf folgenden Button, um Ihre E-Mail-Adresse zu bestätigen:</p>
            <Button onClick={onClick}>E-Mail-Adresse bestätigen</Button>
          </>
        ) : null}
        {state === "requesting" ? <Spinner /> : null}
        {state === "successful" ? (
          <>
            <div style={{ textAlign: "center" }}>
              <Icon path={mdiCheckboxMarkedCircle} size={3} color="#27ae60" />
            </div>
            <p>
              Ihre E-Mail-Adresse wurde erfolgreich bestätigt. Sie können dieses Fenster/Tab nun
              schliessen.
            </p>
          </>
        ) : null}
        {state === "failed" ? "E-Mail-Adresse konnte nicht bestätigt werden: " + error : null}
      </Card>
    </Background>
  );
}

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const InlineLink = styled.a`
  color: #989898;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 15px;
`;

export function EmailEnroll(props: {
  onDone: () => void;
  policy: EmailEnrollment | undefined;
}): JSX.Element {
  const [state, setState] = useState<"init" | "requesting" | "waiting" | "failed">("init");
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState("");
  var domains = props.policy?.domains || [];
  var notDomains = props.policy?.notDomains || [];
  const onClick = useCallback(async () => {
    if (email.trim().length === 0) {
      setError("Bitte geben Sie eine E-Mail-Adresse ein.");
      return;
    }
    if (domains.length > 0 && !domains.includes(email.split("@")[1])) {
      setError("Diese Domain ist nicht erlaubt.");
      return;
    }
    if (notDomains.length > 0 && notDomains.includes(email.split("@")[1])) {
      setError("Diese Domain ist nicht erlaubt.");
      return;
    }
    setState("requesting");
    try {
      await idpc.SendVerifyMail({
        email: email.trim(),
      });
      setState("waiting");
    } catch (err) {
      console.log(err);
      setError(
        "Es ist ein Fehler beim Senden der Bestätigungs-E-Mail aufgetreten. Bitte überprüfen sie ihre E-Mail-Adresse oder versuchen Sie es später nochmals."
      );
      setState("init");
    }
  }, [email]);
  const onBack = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setState("init");
  }, []);
  const onDone = props.onDone;
  useEffect(() => {
    if (state === "waiting") {
      let sub: Subscription | null = null;
      let done = false;
      const watcher = () => {
        const w = idpc.WatchEnrolment({ type: WatchEnrolmentRequest_Type.EMAIL });
        sub = w.subscribe({
          next(e: WatchEnrolmentEvent) {
            if (e.ok) {
              sub?.unsubscribe();
              onDone();
              return;
            }
          },
          error(e) {
            console.log(e);
            if (!done) {
              setTimeout(watcher, 1000);
            }
          },
          complete() {
            return;
          },
        });
      };
      watcher();
      return () => {
        done = true;
        sub?.unsubscribe();
      };
    }
  }, [state]);
  const emailToList = (list: string[]) => {
    return list.map((item) => {
      return <li key={item}>@{item}</li>;
    });
  };
  return (
    <>
      {state === "init" ? (
        <>
          <p>
            Zur Fertigstellung Ihrer Anmeldung müssen Sie eine alternative E-Mail-Adresse
            hinzufügen. Diese wird später z.B. für Passwort-Rücksetzungen gebraucht.
          </p>
          {domains.length > 0 ? (
            <>
              <p>Die Email muss eine der folgenden Endungen haben:</p>
              <ul>{emailToList(domains)}</ul>
            </>
          ) : null}
          {notDomains.length > 0 ? (
            <>
              <p>Die Email darf keine der folgenden Endungen haben: </p>
              <ul>{emailToList(notDomains)}</ul>
            </>
          ) : null}
          <form
            onSubmit={(e: React.SyntheticEvent) => {
              e.preventDefault();
              onClick();
            }}
          >
            <Input
              name="Private E-Mail-Adresse"
              autoFocus
              inputMode="email"
              type="email"
              value={email}
              onChange={setEmail}
            />
            <ErrorMessage error={error} />
            <ButtonBox>
              <Button type="submit">Weiter</Button>
            </ButtonBox>
          </form>
          {props.policy?.required === false ? (
            <p style={{ textAlign: "center" }}>
              <Button color={Secondary} onClick={props.onDone}>
                Später einrichten
              </Button>
            </p>
          ) : null}
        </>
      ) : null}
      {state === "requesting" ? (
        <>
          <Spinner color="black" /> Verifikations-E-Mail wird versendet...
        </>
      ) : null}
      {state === "waiting" ? (
        <>
          <p style={{ marginTop: "15px" }}>
            Eine Verifikations-E-Mail wurde an {email} versendet. Bitte folgen Sie den Instruktionen
            in der E-Mail um die Verifikation abzuschliessen.
          </p>
          <p>
            Sie werden automatisch weitergeleitet, sobald Sie die Verifikation abgeschlossen haben.
          </p>
          <p style={{ textAlign: "center", marginTop: 25, marginBottom: 25 }}>
            <Spinner color="black" />
          </p>
          <p>Nicht erhalten oder falsche E-Mail-Adresse? <InlineLink onClick={onBack}>Weiter mit anderer E-Mail-Adresse</InlineLink></p>
        </>
      ) : null}
    </>
  );
}

export function AddEmailAddress(props: {}): JSX.Element {
  return <div />;
}

// http://stackoverflow.com/a/10899795/604296
function addThousandsSeparator(n: string) {
  return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

enum SendStatus {
  SENDING,
  SENT,
  ERROR,
}

export function AuthMail(props: {
  spec: EmailAddress;
  authCtx?: AuthContext;
  authContinue: (c: AuthContinue) => void;
}): JSX.Element {
  const { id } = props.spec;
  const [code, setCode] = useState("");
  const [result, setResult] = useState<AuthBackchannelResponse_Result | null>(null);
  const [error, setError] = useState<string | null>(null);

  const setCodeWrapper = useCallback((newCode: string) => {
    setCode(addThousandsSeparator(newCode));
  }, []);

  const [state, setState] = useState<SendStatus>(SendStatus.SENDING);

  useEffect(() => {
    async function sendBackchannel() {
      try {
        await idpc.SendAuthMail({ id });
        setState(SendStatus.SENT);
      } catch (err) {
        setState(SendStatus.ERROR);
      }
    }
    sendBackchannel();
  }, [id]);

  const onNext = useCallback(async () => {
    try {
      setError(null);
      setResult(null);
      const res = await idpc.AuthBackchannel({ code: parseInt(code.replace(/\D/g, ""), 10) });
      if (res.result === AuthBackchannelResponse_Result.SUCCESS) {
        props.authContinue(res.authContinue!);
      }
      setResult(res.result);
      setCode("");
    } catch (err) {
      setError(err.toString());
    }
  }, [code, props]);

  const emailText = useDescription(props.spec);

  let displayError: string | null = null;
  if (error !== null) {
    displayError = "Bitte versuchen Sie es später nochmals. Details: " + error;
  } else {
    switch (result) {
      case AuthBackchannelResponse_Result.WRONG_CODE:
        displayError = "Falscher Code.";
        break;
      case AuthBackchannelResponse_Result.RATE_LIMITED:
        return (
          <ErrorLayout
            icon={mdiTimerSand}
            primaryMessage="Sie haben in zu viele fehlgeschlagene Versuche in den letzten paar Minuten. Bitte probieren Sie es später wieder."
            secondaryMessage="Nächster Versuch in 5min"
          />
        );
    }
  }

  return (
    <Container>
      <EmailAddressItem spec={props.spec} />
      <Prompt>Bitte geben Sie den Sicherheitscode ein, der an {emailText} gesendet wurde:</Prompt>
      <Input
        name="Sicherheitscode"
        type="text"
        value={code}
        inputMode="numeric"
        autoFocus
        autoComplete="one-time-code"
        onChange={setCodeWrapper}
        onEnter={onNext}
      />
      <ErrorMessage error={displayError} />
      <Button onClick={onNext}>Weiter</Button>
    </Container>
  );
}
