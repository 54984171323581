import { grpc } from "@improbable-eng/grpc-web";
import { mdiLockAlert } from "@mdi/js";
import React, { useCallback, useState } from "react";
import styled from "styled-components/macro";
import { idpc } from "../api";
import { Button } from "../Button";
import { ErrorMessage } from "../ErrorMessage";
import { AuthContinue, AuthUsernameResponse_Result } from "../generated/idp/api/idp";
import { Input } from "../Input";
import { ErrorLayout } from "./ErrorLayout";

const Container = styled.div``;

const Prompt = styled.div`
  color: #505050;
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export function Username(props: {
  authContinue: (c: AuthContinue) => void;
  authRecover: () => void;
  hasLegacyUsernames: boolean;
}): JSX.Element {
  const [username, setUsername] = useState("");
  const [result, setResult] = useState<AuthUsernameResponse_Result | null>(null);
  const [error, setError] = useState<string | null>(null);

  const onNext = useCallback(async () => {
    try {
      setError(null);
      setResult(null);
      const res = await idpc.AuthUsername({ username: username });
      if (res.result === AuthUsernameResponse_Result.SUCCESS) {
        props.authContinue(res.authContinue!);
      }
      setResult(res.result);
    } catch (err) {
      if (err.code === grpc.Code.Unauthenticated) {
        props.authRecover();
      }
      setError(err.toString());
    }
  }, [username, props]);

  const resetCb = useCallback(() => {
    setUsername("");
    setResult(null);
    setError(null);
  }, []);

  let displayError: string | null = null;
  if (error !== null) {
    displayError = "Bitte versuchen Sie es später nochmals. Details: " + error;
  } else {
    switch (result) {
      case AuthUsernameResponse_Result.NOT_FOUND:
        displayError = "E-Mail oder Benutzername nicht gefunden.";
        break;
      case AuthUsernameResponse_Result.POLICY_VIOLATION:
        return (
          <ErrorLayout
            icon={mdiLockAlert}
            primaryMessage="Sicherheitsrichtlinen verbieten diese Operation."
            secondaryMessage="Wenn Sie der Meinung sind, dass das erlaubt sein sollte, melden Sie sich beim technischen Dienst unter technik@bsrueti.ch."
            backButtonText="Anderer Benutzer verwenden"
            onBack={resetCb}
          />
        );
      case AuthUsernameResponse_Result.DISABLED:
        return (
          <ErrorLayout
            icon={mdiLockAlert}
            primaryMessage="Dieser Benutzer ist deaktiviert. Sie können sich damit nicht anmelden."
            secondaryMessage="Wenn Sie der Meinung sind, dass der Benutzer aktiv sein sollte, melden Sie sich beim technischen Dienst unter technik@bsrueti.ch."
            backButtonText="Anderer Benutzer verwenden"
            onBack={resetCb}
          />
        );
    }
  }

  return (
    <Container>
      <Prompt>
        Bitte melden Sie sich mit Ihrer E-Mail-Adresse{" "}
        {props.hasLegacyUsernames ? "oder Ihrem Benutzernamen" : ""} an:
      </Prompt>
      <Input
        name={"E-Mail-Adresse" + (props.hasLegacyUsernames ? " oder Benutzername" : "")}
        type="text"
        value={username}
        autoFocus
        inputMode="email"
        autoComplete="username webauthn"
        onChange={setUsername}
        onEnter={onNext}
      />
      <ErrorMessage error={displayError} />
      <ButtonBox>
        <Button onClick={onNext}>Weiter</Button>
      </ButtonBox>
    </Container>
  );
}
